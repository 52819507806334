import { Outlet } from "react-router-dom";
import './App.css';
import * as React from 'react';

const Layout = () => {
    return (
        <main className="App">
            <Outlet />
        </main>
    )
}

export { Layout };