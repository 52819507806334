import * as React from 'react';
import TextField from '@mui/material/TextField';
import Typography from '@mui/material/Typography';
import Grid from '@mui/material/Unstable_Grid2';
import Button from '@mui/material/Button';
import axios from 'axios';

function AddVessel() {
  const [data, setData] = React.useState({
    header: "VESSEL_INSERT",
    name: null,
    type: null,
    imo: null,
    official_no: null,
    year_registered: null
  });

  const nameInput = React.useRef(null);
  const typeInput = React.useRef(null);
  const imoInput = React.useRef(null);
  const official_noInput = React.useRef(null);
  const year_registeredInput = React.useRef(null);

  const [x, setX] = React.useState();

  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      const result = await axios.post('https://ssdejavu.duckdns.org/api/ops.php',
        data,
        {
          headers: {
            'content-type': 'application/json; charset=utf-8',
            'cache-control': 'no-cache',
          }
        });
      setData({
        header: null,
        name: null,
        type: null,
        imo: null,
        official_no: null,
        year_registered: null
      });
      e.target.reset();
      /*nameInput = null;
      typeInput = null;
      imoInput = null;
      official_noInput = null;
      year_registeredInput = null;*/

      setX(result.data);

    } catch (error) {
      console.log(error);
      //ALERT
    }
  }

  const handleChange = (e) => {
    const name = e.target.name;
    const value = e.target.value;
    setData(prevData => ({ ...prevData, [name]: value }));
  }

  return (
    <>
      <Typography variant='h4' align='Left' sx={{ paddingBottom: '1cm', paddingLeft: '0.2cm' }}>
        Add Vessel
      </Typography>
      <form onSubmit={handleSubmit}>
        <Grid
          container
          spacing={2}
          direction='row'
          justifyContent="flex-start"
          alignItems="baseline"
          width="21cm"
        >
          <Grid xs={12}>
            <TextField
              required
              autoComplete='off'
              label="Vessel Name"
              name='name'
              inputRef={nameInput}
              onChange={handleChange}
              variant="filled"
              size="small"
              sx={{ width: '50ch', float: 'left' }}
            />
          </Grid>
          <Grid xs={6}>
            <TextField
              required
              autoComplete='off'
              label="Vessel Type"
              name='type'
              inputRef={typeInput}
              onChange={handleChange}
              variant="filled"
              size="small"
              sx={{ width: '35ch', float: 'left' }}
            />
          </Grid>
          <Grid xs={6}>
            <TextField
              required
              autoComplete='off'
              label="IMO No."
              name='imo'
              inputRef={imoInput}
              type='number'
              onChange={handleChange}
              variant="filled"
              size="small"
              sx={{ width: '35ch', float: 'left' }}
            />
          </Grid>
          <Grid xs={6}>
            <TextField
              required
              autoComplete='off'
              label="Official No."
              name='official_no'
              inputRef={official_noInput}
              onChange={handleChange}
              variant="filled"
              size="small"
              sx={{ width: '35ch', float: 'left' }}
            />
          </Grid>
          <Grid xs={6}>
            <TextField
              required
              autoComplete='off'
              label="Year Registered"
              name='year_registered'
              inputRef={year_registeredInput}
              type='number'
              onChange={handleChange}
              variant="filled"
              size="small"
              sx={{ width: '35ch', float: 'left' }}
            />
          </Grid>
          <Grid xs={12} sx={{ pr: '2cm' }}>
            <Button
              variant="contained"
              type='submit'
              sx={{ width: '3cm', float: 'right' }}
            >
              Submit
            </Button>
          </Grid>
        </Grid>
      </form>
      DATA: {JSON.stringify(data)}
      <br></br>
      RESPONSE: {JSON.stringify(x)}

    </>

  );

}
export default AddVessel;