import * as React from 'react';
import Button from '@mui/material/Button';
import FormControl from '@mui/material/FormControl';
import TextField from '@mui/material/TextField';
import AuthContext from "../Context/AuthProvider";

import { Link, useNavigate, useLocation, json } from 'react-router-dom';
import axios from 'axios';
function Login() {
  const { userData, setUserData } = React.useContext(AuthContext);
  const navigate = useNavigate();
  const location = useLocation();
  const from = location.state?.from?.pathname || "/dashboard";

  const [data, setData] = React.useState({
    username: null,
    password: null,
  });
  const usernameInput = React.useRef(null);
  const passwordInput = React.useRef(null);

  const handleChange = (e) => {
    const type = e.target.type;
    const name = e.target.name;
    const value = type === "checkbox"
      ? e.target.checked
      : e.target.value

    setData(prevData => ({ ...prevData, [name]: value }));
  }
  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      const result = await axios.post('https://ssdejavu.duckdns.org/api/login.php',
        data,
        {
          headers: {
            //'content-type': 'application/json',
            'content-type': 'application/json; charset=utf-8',
            'cache-control': 'no-cache',
          }
        });
      setUserData(result.data);
      //setAuth(result.data);
      setData({
        username: null,
        password: null,
      });
      usernameInput.current.value = null;
      passwordInput.current.value = null;
      //if not login (by checking Auth in context, previous link is stored to be redirected to it upon successful login.
      navigate(from, { replace: true });
    } catch (error) {
      console.log(error);
      //ALERT
    }
  }

  return (
    <>
      <FormControl>
        <h2>Login</h2>
        <TextField
          label="Username"
          inputRef={usernameInput}
          name="username"
          type="text"
          variant="filled"
          size="small"
          onChange={handleChange}
        />
        <br></br>
        <TextField
          label="Password"
          inputRef={passwordInput}
          name="password"
          variant="filled"
          size="small"
          type="password"
          onChange={handleChange}

        />
        <br></br>
        <Button variant="contained" type='submit' onClick={handleSubmit}>Login</Button>

      </FormControl>
      {/* 
      <h2>AUTH : {JSON.stringify(auth)}</h2> <br/>
      <h2>USER : {JSON.stringify(userData)}</h2>
      */}

    </>
  );

}

export { Login }