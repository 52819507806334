import * as React from 'react';
import { SendQuery2 } from '../Component/ToServer.js';
import { sampleCrewTOD2 } from '../Context/SampleCrewTOD.js';
import dayjs from 'dayjs';
import './TODTable.css';

//DELETE 
import TODCrewDel from './TODCrewDel';
import DeleteOutlinedIcon from '@mui/icons-material/DeleteOutlined';
import IconButton from '@mui/material/IconButton';
/*
FUNCTION: TODTable(p)

p.tod_id:: tod_id FK for crew_tod(SQL table)
p.updateTrigger:: receives trigger to update
 */
function TODTable(p) {
  const [todTable, setTodTable] = React.useState([]);
  const [open, setOpen] = React.useState(false);
  const [crewData, setCrewData] = React.useState({});
  const [delUpdateTrigger, setDelUpdateTigger] = React.useState(0);
  const formatter = new Intl.NumberFormat('en-US', {
    style: 'currency',
    currency: 'MYR',
    currencyDisplay: "code"

    // These options are needed to round to whole numbers if that's what you want.
    //minimumFractionDigits: 0, // (this suffices for whole numbers, but will print 2500.10 as $2,500.1)
    //maximumFractionDigits: 0, // (causes 2500.99 to be printed as $2,501)
  });
  //dayjs(1666627200 * 1000).format("DD MMM YYYY")
  const handleClick = (row, j_salary) => (e) => {
    if (row !== undefined) {
      //FIRE UP DELETE DIALOG
      setCrewData({
        crew_tod_id: j_salary.id,
        name: row.name,
        start_date: j_salary.start_date,
        end_date: j_salary.end_date,
        rate: j_salary.rate,
        days: j_salary.days,
        category: j_salary.category
      });
      setOpen(true);
    }
  };
  const handleDelUpdate = () => {
    setDelUpdateTigger(prev => prev % 2 + 1);
  };

  const tableBody = todTable?.map((row, index) => {
    return (
      <>
        <tr className='firstRow' onDoubleClick={handleClick(row, row.j_salary[0])}>
          <td key={row.id} className='test2'>{index + 1}</td>
          <td>{row.name}</td>
          <td className='thRank'>{row.job_title}</td>
          <td>{row.j_salary[0].category}</td>
          <td className='myTextAlignRight'>
            {formatter.format(row.j_salary[0].rate).replace("MYR", "").trim()}
          </td>
          <td className='myTextAlignCenter'>
            {row.sign_on === 0 || row.sign_on === undefined
              ? ""
              : dayjs(row.sign_on * 1000).format("DD-MM-YYYY")}
          </td>
          <td className='myTextAlignCenter'>
            {row.sign_off === 0 || row.sign_off === undefined
              ? ""
              : dayjs(row.sign_off * 1000).format("DD-MM-YYYY")}
          </td>
          <td className='myTextAlignCenter'>
            {row.j_salary[0]?.start_date === 0 || row.j_salary[0]?.start_date === undefined
              ? ""
              : dayjs(row.j_salary[0]?.start_date * 1000).format("DD-MM-YYYY")}
          </td>
          <td className='myTextAlignCenter'>
            {row.j_salary[0]?.end_date === 0 || row.j_salary[0]?.end_date === undefined
              ? ""
              : dayjs(row.j_salary[0]?.end_date * 1000).format("DD-MM-YYYY")}
          </td>
          <td className='myTextAlignCenter'>
            {row.j_salary[0].days}
          </td>
          <td className='myTextAlignRight'>
            {row.j_salary[0]?.salary === 0 || row.j_salary[0]?.salary === undefined
              ? ""
              : formatter.format(row.j_salary[0].salary).replace("MYR", "").trim()}
          </td>
        </tr>
        <tr className='secondRow' onDoubleClick={handleClick(row, row.j_salary[1])}>
          <td name="r2cNo" className='rowHeight'></td>
          <td name="r2cDetails"></td>
          <td name="r2cRank"></td>
          <td name="r2cCat">
            {row.j_salary[1]?.category === "" || row.j_salary[1]?.category === undefined
              ? ""
              : row.j_salary[1].category}
          </td>
          <td name="r2cRate" className='myTextAlignRight'>
            {row.j_salary[1]?.rate === 0 || row.j_salary[1]?.rate === undefined
              ? ""
              : formatter.format(row.j_salary[1].rate).replace("MYR", "").trim()}
          </td>
          <td name="r2cS_On"></td>
          <td name="r2cS_Off"></td>
          <td name="r2cStart" className='myTextAlignCenter'>
            {row.j_salary[1]?.start_date === 0 || row.j_salary[1]?.start_date === undefined
              ? ""
              : dayjs(row.j_salary[1]?.start_date * 1000).format("DD-MM-YYYY")}
          </td>
          <td name="r2cEnd" className='myTextAlignCenter'>
            {row.j_salary[1]?.end_date === 0 || row.j_salary[1]?.end_date === undefined
              ? ""
              : dayjs(row.j_salary[1]?.end_date * 1000).format("DD-MM-YYYY")}
          </td>
          <td name="r2cDays" className='myTextAlignCenter'>
            {row.j_salary[1]?.days === 0 || row.j_salary[1]?.days === undefined
              ? ""
              : row.j_salary[1]?.days}
          </td>
          <td name="r2cSal" className='myTextAlignRight'>
            {row.j_salary[1]?.salary === 0 || row.j_salary[1]?.salary === undefined
              ? ""
              : formatter.format(row.j_salary[1].salary).replace("MYR", "").trim()}
          </td>
        </tr>
        <tr className='secondRow' onDoubleClick={handleClick(row, row.j_salary[2])}>
          <td name="r3cNo"></td>
          <td name="r3cDetails">{row.nationality}</td>
          <td name="r3cRank"></td>
          <td name="r3cCat">
            {row.j_salary[2]?.category === "" || row.j_salary[2]?.category === undefined
              ? ""
              : row.j_salary[2].category}
          </td>
          <td name="r3cRate" className='myTextAlignRight'>
            {row.j_salary[2]?.rate === 0 || row.j_salary[2]?.rate === undefined
              ? ""
              : formatter.format(row.j_salary[2].rate).replace("MYR", "").trim()}
          </td>
          <td name="r3cS_On"></td>
          <td name="r3cS_Off"></td>
          <td name="r3cStart" className='myTextAlignCenter'>
            {row.j_salary[2]?.start_date === 0 || row.j_salary[2]?.start_date === undefined
              ? ""
              : dayjs(row.j_salary[2]?.start_date * 1000).format("DD-MM-YYYY")}
          </td>
          <td name="r3cEnd" className='myTextAlignCenter'>
            {row.j_salary[2]?.end_date === 0 || row.j_salary[2]?.end_date === undefined
              ? ""
              : dayjs(row.j_salary[2]?.end_date * 1000).format("DD-MM-YYYY")}
          </td>
          <td name="r3cDays" className='myTextAlignCenter'>
            {row.j_salary[2]?.days === 0 || row.j_salary[2]?.days === undefined
              ? ""
              : row.j_salary[2]?.days}
          </td>
          <td name="r3cSal" className='myTextAlignRight'>
            {row.j_salary[2]?.salary === 0 || row.j_salary[2]?.salary === undefined
              ? ""
              : formatter.format(row.j_salary[2].salary).replace("MYR", "").trim()}
          </td>
        </tr>
        <tr className='secondRow' onDoubleClick={handleClick(row, row.j_salary[3])}>
          <td name="r4cNo"></td>
          <td name="r4cDetails">{row.ic_passport}</td>
          <td name="r4cRank">{row.pcb_col}</td>
          <td name="r4cCat">
            {row.j_salary[3]?.category === "" || row.j_salary[3]?.category === undefined
              ? ""
              : row.j_salary[3].category}
          </td>
          <td name="r4cRate" className='myTextAlignRight'>
            {row.j_salary[3]?.rate === 0 || row.j_salary[3]?.rate === undefined
              ? ""
              : formatter.format(row.j_salary[3].rate).replace("MYR", "").trim()}

          </td>
          <td name="r4cS_On"></td>
          <td name="r4cS_Off"></td>
          <td name="r4cStart" className='myTextAlignCenter'>
            {row.j_salary[3]?.start_date === 0 || row.j_salary[3]?.start_date === undefined
              ? ""
              : dayjs(row.j_salary[3]?.start_date * 1000).format("DD-MM-YYYY")}
          </td>
          <td name="r4cEnd" className='myTextAlignCenter'>
            {row.j_salary[3]?.end_date === 0 || row.j_salary[3]?.end_date === undefined
              ? ""
              : dayjs(row.j_salary[3]?.end_date * 1000).format("DD-MM-YYYY")}
          </td>
          <td name="r4cDays" className='myTextAlignCenter'>
            {row.j_salary[3]?.days === 0 || row.j_salary[3]?.days === undefined
              ? ""
              : row.j_salary[3]?.days}
          </td>
          <td name="r4cSal" className='myTextAlignRight'>
            {row.j_salary[3]?.salary === 0 || row.j_salary[3]?.salary === undefined
              ? ""
              : formatter.format(row.j_salary[3]?.salary).replace("MYR", "").trim()}
          </td>
        </tr>
        <tr className='fifthRow'>
          <td></td>
          <td></td>
          <td></td>
          <td></td>
          <td></td>
          <td></td>
          <td></td>
          <td></td>
          <td></td>
          <td className='totalRow'>Total:</td>
          <td className='totalRow'>
            {formatter.format(row.sum_salary).replace("MYR", "").trim()}
          </td>
        </tr>
        <tr className='rowHeight'><td></td></tr>
      </>
    );
  });




  React.useEffect(() => {
    const fetchData = async () => {
      const result = await SendQuery2({ header: 'TOD_TABLE_LIST', tod_id: p.tod_id }, "ops.php");

      //for OFFLINE use only
      if (result == "Network Error") {
        setTodTable(sampleCrewTOD2);
      } else {
        setTodTable(result.data);
        //setX(eval(result.data[18]?.j_salary));
      }

    };
    if (p.tod_id != undefined) {
      fetchData();
    } else {
      ;//setTodTable(sampleCrewTOD2);
    }
  }, [p.tod_id, p.updateTrigger, delUpdateTrigger]);

  //RESETS the table when USER selects other WageReport because Vessel is UNSET
  React.useEffect(() => {
    if (todTable.length != 0) {
      setTodTable([]);
    }
  }, [p.updateTriggerWR]);

  return (
    <>
      <table>
        <thead>
          <tr className='thRow'>
            <th className='thNo'>No</th>
            <th>Details</th>
            <th>Rank</th>
            <th>Category</th>
            <th className='thRate'>Rate</th>
            <th className='thDate'>Sign On</th>
            <th className='thDate'>Sign Off</th>
            <th className='thDate'>Date Start</th>
            <th className='thDate'>Date End</th>
            <th>Days</th>
            <th className='thWages'>Wages</th>
            <th></th>
          </tr>
        </thead>
        <tbody>
          {
            tableBody
          }
        </tbody>
      </table>
      {
        //JSON.stringify(todTable)
      }
      <TODCrewDel
        open={open}
        setOpen={setOpen}
        data={crewData}
        handleDelUpdate={handleDelUpdate}
      />
    </>
  );
}
export default TODTable;