const sampleWageReportListMT = [{}]

const sampleWageReportList = [
   {
      "id": 5,
      "opening_date": "28 November 2022",
      "closing_date": "30 November 2022",
      "prepared_by": "Chew Yong Wui",
      "prepared_date": "2022-11-14",
      "verified_by": null,
      "verified_date": "0000-00-00",
      "approved_by": null,
      "approved_date": "0000-00-00"
   },
   {
      "id": 7,
      "opening_date": "02 November 2022",
      "closing_date": "30 November 2022",
      "prepared_by": "Chew Yong Wui",
      "prepared_date": "2022-11-14",
      "verified_by": null,
      "verified_date": "0000-00-00",
      "approved_by": null,
      "approved_date": "0000-00-00"
   },
   {
      "id": 11,
      "opening_date": "28 October 2022",
      "closing_date": "27 November 2022",
      "prepared_by": "Chew Yong Wui",
      "prepared_date": "2022-11-27",
      "verified_by": null,
      "verified_date": "0000-00-00",
      "approved_by": null,
      "approved_date": "0000-00-00"
   },
   {
      "id": 6,
      "opening_date": "10 November 2022",
      "closing_date": "26 November 2022",
      "prepared_by": "Chew Yong Wui",
      "prepared_date": "2022-11-14",
      "verified_by": null,
      "verified_date": "0000-00-00",
      "approved_by": null,
      "approved_date": "0000-00-00"
   },
   {
      "id": 4,
      "opening_date": "07 November 2022",
      "closing_date": "19 November 2022",
      "prepared_by": "Chew Yong Wui",
      "prepared_date": "2022-11-14",
      "verified_by": null,
      "verified_date": "0000-00-00",
      "approved_by": null,
      "approved_date": "0000-00-00"
   },
   {
      "id": 9,
      "opening_date": "16 October 2022",
      "closing_date": "17 November 2022",
      "prepared_by": "Chew Yong Wui",
      "prepared_date": "2022-11-21",
      "verified_by": null,
      "verified_date": "0000-00-00",
      "approved_by": null,
      "approved_date": "0000-00-00"
   },
   {
      "id": 2,
      "opening_date": "01 November 2022",
      "closing_date": "05 November 2022",
      "prepared_by": "Chew Yong Wui",
      "prepared_date": "2022-11-14",
      "verified_by": null,
      "verified_date": "0000-00-00",
      "approved_by": null,
      "approved_date": "0000-00-00"
   },
   {
      "id": 3,
      "opening_date": "01 November 2022",
      "closing_date": "04 November 2022",
      "prepared_by": "Chew Yong Wui",
      "prepared_date": "2022-11-14",
      "verified_by": null,
      "verified_date": "0000-00-00",
      "approved_by": null,
      "approved_date": "0000-00-00"
   },
   {
      "id": 8,
      "opening_date": "01 October 2022",
      "closing_date": "31 October 2022",
      "prepared_by": "Chew Yong Wui",
      "prepared_date": "2022-11-21",
      "verified_by": null,
      "verified_date": "0000-00-00",
      "approved_by": null,
      "approved_date": "0000-00-00"
   },
   {
      "id": 12,
      "opening_date": "26 September 2022",
      "closing_date": "25 October 2022",
      "prepared_by": "Chew Yong Wui",
      "prepared_date": "2022-11-27",
      "verified_by": null,
      "verified_date": "0000-00-00",
      "approved_by": null,
      "approved_date": "0000-00-00"
   },
   {
      "id": 10,
      "opening_date": "06 October 2022",
      "closing_date": "15 October 2022",
      "prepared_by": "Chew Yong Wui",
      "prepared_date": "2022-11-21",
      "verified_by": null,
      "verified_date": "0000-00-00",
      "approved_by": null,
      "approved_date": "0000-00-00"
   }
];

export { sampleWageReportList, sampleWageReportListMT }