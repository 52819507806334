import * as React from 'react';
import axios from 'axios';
import Box from '@mui/material/Box';
//DIALOG
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
//DROPDOWN MENU
import MenuItem from '@mui/material/MenuItem';
import Select from '@mui/material/Select';
import FormControl from '@mui/material/FormControl';
import InputLabel from '@mui/material/InputLabel';

function DelWageReport(p) {
  const [open, setOpen] = React.useState(false);
  const [key, setKey] = React.useState(-1);
  const [disableButton, setDisableButton] = React.useState(true);

  const menuList = p.wageReportList.map((row) => {
    return (
      <MenuItem value={row['id']}>
        {row['id']} | {row['closing_date']}
      </MenuItem>
    );
  });

  const handleClickOpen = () => {
    setOpen(true);
    setDisableButton(true);

  };
  const handleClose = () => {
    setOpen(false);
    p.setUpdateToken(prev => prev % 2 + 1);
  };

  const handleSelect = (e) => {
    setKey(e.target.value);
    setDisableButton(false);
  }
  const handleSubmit = async (e) => {
    try {

      const result = await axios.post('https://ssdejavu.duckdns.org/api/ops.php',
        { header: "WAGE_REPORT_SET_INACTIVE", wr_id: key },
        {
          headers: {
            'content-type': 'application/json; charset=utf-8',
            'cache-control': 'no-cache',
          }
        });
      handleClose();
      console.log(result);

    } catch (error) {
      console.log(error);
    }
  }


  return (
    <>
      <Button variant="outlined" color="error" onClick={handleClickOpen}>
        DELETE
      </Button>
      <Dialog
        open={open}
        onClose={handleClose}
      >
        <DialogTitle>
          DELETE MONTHLY WAGE REPORT {key}
        </DialogTitle>
        <DialogContent>
          <DialogContentText>
            Please select the "Wage Report" to be deleted.
          </DialogContentText>
          <Box width='400px' sx={{ pt: '10px', pb: '15px' }}>
            <FormControl variant="standard" sx={{ m: 1, minWidth: 220, float: 'left' }}>
              <InputLabel>Wage Report: Closing Date</InputLabel>
              <Select
                labelId="demo-simple-select-standard-label"
                id="demo-simple-select-standard"
                onChange={handleSelect}
                label="WageReport"
              >
                {menuList}
              </Select>
            </FormControl>


          </Box>
          <DialogContentText>

          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose}>CANCEL</Button>
          <Button onClick={handleSubmit} autoFocus color="error" disabled={disableButton}>
            DELETE
          </Button>
        </DialogActions>
      </Dialog>

    </>
  );

}
export default DelWageReport;