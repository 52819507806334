import * as React from 'react';
import axios from 'axios';
import Typography from '@mui/material/Typography';
import Grid from '@mui/material/Unstable_Grid2';
import Box from '@mui/material/Box';

//AUTO COMPLETE
import Autocomplete from '@mui/material/Autocomplete';

//DIALOG
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import dayjs from 'dayjs';
/****
 * 
 * p.open
 * p.setOpen
 * p.data.crew_tod_id
 * 
 */
function TODCrewDel(p) {
  const formatter = new Intl.NumberFormat('en-US', {
    style: 'currency',
    currency: 'MYR',
    currencyDisplay: "code"
  });
  const [msg, setMsg] = React.useState();
  const handleClose = () => {
    p.setOpen(false);
  };

  const handleSubmitClick = async (e) => {
    try {
      const result = await axios.post('https://ssdejavu.duckdns.org/api/ops.php',
        { header: "TOD_CREW_DEL", crew_tod_id: p.data.crew_tod_id },
        {
          headers: {
            'content-type': 'application/json; charset=utf-8',
            'cache-control': 'no-cache',
          }
        });
      p.handleDelUpdate();
      handleClose();
      
      setMsg(result.data); 
      /*result.data returns 
      0 :: Vessel is not added into TOD
      due to INSERT IGNORE as compound vessel_id & wr_id is not unique
      1 :: Operation is sucessful
        */
      //TRIGGER TO UPDATE WORKREPORT table

    } catch (error) {
      console.log(error);
      //ALERT    
    }

  };

  return (
    <Dialog
      open={p.open}
      onClose={handleClose}
    >
      <DialogTitle>
        Tour Of Duty: Remove Crew Entry
      </DialogTitle>
      <DialogContent>
        <DialogContentText>
          The below TOD crew entry will be removed
          <Box sx={{ m: '15px' }}>
            <Typography variant='caption'>
              Name: {p.data.name} <br></br>
              Start Date: {dayjs(p.data.start_date * 1000).format("DD-MM-YYYY")} <br></br>
              End Date: {dayjs(p.data.end_date * 1000).format("DD-MM-YYYY")} <br></br>
              Salary: RM {p.data.rate} x {p.data.days} days = RM {formatter.format(p.data.rate * p.data.days).replace("MYR", "").trim()} <br></br>
            </Typography>
          </Box>
          <Typography color='brown'>Note: Deleted data is unretrievable.</Typography>
        </DialogContentText>
      </DialogContent>
      <DialogActions>
        <Button onClick={handleClose}>CANCEL</Button>
        <Button onClick={handleSubmitClick} autoFocus>
          DELETE
        </Button>
      </DialogActions>
    </Dialog>

  );
}

export default TODCrewDel;