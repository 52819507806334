import * as React from 'react';
import { useLocation, Navigate, Outlet } from "react-router-dom";
import useAuth from "../Hooks/useAuth";

const RequireFleetAuth = () => {
  const { userData } = useAuth();
  const location = useLocation();

  return (
    userData?.role?.includes('ADMIN')
      ? <Outlet />
      : <Navigate to="/Login" state={{ from: location }} replace />
  );

}

export default RequireFleetAuth;