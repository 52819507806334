import * as React from 'react';
import './Drawer.css';
import PropTypes from 'prop-types';
import AppBar from '@mui/material/AppBar';
import Box from '@mui/material/Box';
import Grid from '@mui/material/Unstable_Grid2';
import CssBaseline from '@mui/material/CssBaseline';
import Divider from '@mui/material/Divider';
import Drawer from '@mui/material/Drawer';
import IconButton from '@mui/material/IconButton';
import InboxIcon from '@mui/icons-material/MoveToInbox';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import ListItemButton from '@mui/material/ListItemButton';
import MailIcon from '@mui/icons-material/Mail';
import MenuIcon from '@mui/icons-material/Menu';
import Toolbar from '@mui/material/Toolbar';
import Typography from '@mui/material/Typography';

//import MyRoutes from './Routes.js';
import MainRoutes from './MainRoutes.js';

import { Routes, Route, Link } from "react-router-dom";

import RequireAuth from './RequireAuth';
import RequireFleetAuth from './RequireFleetAuth';
import { Unauthorized } from './Unauthorized';
import { Dashboard } from '../Dashboard/Dashboard.js';
import { User } from '../User/User.js';
import { Layout } from './Layout.js';
import { Login } from '../Login/Login.js';

import { TourOfDuty } from '../Calculator/TourOfDuty.js';
import { WageOverview } from '../Calculator/WageOverview.js';

import { AddVessel } from '../Fleet/AddVessel.js';

import AccountMenu from '../User/AccountMenu.js';

//own hook
import AuthContext from "../Context/AuthProvider";

//Print
import ReactToPrint from "react-to-print";

const drawerWidth = 240;

function ResponsiveDrawer(props) {

  /*const Print = () =>{     
    //console.log('print');  
    let printContents = document.getElementById('printablediv').innerHTML;
    let originalContents = document.body.innerHTML;
    document.body.innerHTML = printContents;
    window.print();
   document.body.innerHTML = originalContents; 
  }*/

  const { window } = props;
  const [mobileOpen, setMobileOpen] = React.useState(false);
  const [selectedIndex, setSelectedIndex] = React.useState(1);
  const { loginToggle, setLoginToggle } = React.useContext(AuthContext);

  const handleDrawerToggle = () => {
    setMobileOpen(!mobileOpen);
  };

  const handleListItemClick = (event, index) => {
    setSelectedIndex(index);
    setLoginToggle(prev => prev % 2 + 1);
  };

  const drawer = (
    <div>
      <Toolbar />
      <Divider />
      <List>
        <ListItem>
          <ListItemText primary="Wage Calculator" />
        </ListItem>
        <Link to="wagereport" style={{ textDecoration: 'none', color: 'black' }}>
          <ListItemButton
            selected={selectedIndex === 3}
            onClick={(event) => handleListItemClick(event, 3)}
            sx={{ pl: 4 }}
          >
            <ListItemText primary="Wage Report" />
          </ListItemButton>
        </Link>
        <Link to="wageoverview" style={{ textDecoration: 'none', color: 'black' }}>
          <ListItemButton
            selected={selectedIndex === 1}
            onClick={(event) => handleListItemClick(event, 1)}
            sx={{ pl: 4 }}
          >
            <ListItemText primary="Wage Overview" />
          </ListItemButton>
        </Link>
        <Link to="tourofduty" style={{ textDecoration: 'none', color: 'black' }}>
          <ListItemButton
            selected={selectedIndex === 2}
            onClick={(event) => handleListItemClick(event, 2)}
            sx={{ pl: 4 }}
          >
            <ListItemText primary="Tour Of Duty" />
          </ListItemButton>
        </Link>
      </List>
      <List>
        <ListItem>
          <ListItemText primary="Fleet" />
        </ListItem>
        <Link to="listvessel" style={{ textDecoration: 'none', color: 'black' }}>
          <ListItemButton
            selected={selectedIndex === 'F1'}
            onClick={(event) => handleListItemClick(event, 'F1')}
            sx={{ pl: 4 }}
          >
            <ListItemText primary="List of Vessels" />
          </ListItemButton>
          <Link to="addvessel" style={{ textDecoration: 'none', color: 'black' }}>
            <ListItemButton
              selected={selectedIndex === 'F2'}
              onClick={(event) => handleListItemClick(event, 'F2')}
              sx={{ pl: 4 }}
            >
              <ListItemText primary="Add Vessel" />
            </ListItemButton>
          </Link>

        </Link>
      </List>
      <Divider />
      <List>
        <ListItem>
          <ListItemText primary="Accounts" />
        </ListItem>
        <Link to="login" style={{ textDecoration: 'none', color: 'black' }}>
          <ListItemButton
            selected={selectedIndex === 101}
            onClick={(event) => handleListItemClick(event, 101)}
            sx={{ pl: 4 }}
          >
            <ListItemText primary="Login" />
          </ListItemButton>
        </Link>
        <Link to="dashboard" style={{ textDecoration: 'none', color: 'black' }}>
          <ListItemButton
            selected={selectedIndex === 102}
            onClick={(event) => handleListItemClick(event, 102)}
            sx={{ pl: 4 }}
          >
            <ListItemText primary="Dashboard" />
          </ListItemButton>
        </Link>
      </List>
    </div>
  );

  const container = window !== undefined ? () => window().document.body : undefined;

  return (
    <Box sx={{ display: 'flex' }}>
      <CssBaseline />
      <AppBar
        position="fixed"
        sx={{
          width: { sm: `calc(100% - ${drawerWidth}px)` },
          ml: { sm: `${drawerWidth}px` },
        }}
      >
        <Toolbar>
          <IconButton
            color="inherit"
            aria-label="open drawer"
            edge="start"
            onClick={handleDrawerToggle}
            sx={{ mr: 2, display: { sm: 'none' } }}
          >
            <MenuIcon />
          </IconButton>
          <Typography variant="h6" noWrap component="div">
            Resource Management
          </Typography>
          <div class="AccountMenu">
            <AccountMenu />
          </div>
        </Toolbar>
      </AppBar>
      <Box
        component="nav"
        sx={{ width: { sm: drawerWidth }, flexShrink: { sm: 0 } }}
        aria-label="mailbox folders"
      >
        {/* The implementation can be swapped with js to avoid SEO duplication of links. */}
        <Drawer
          container={container}
          variant="temporary"
          open={mobileOpen}
          onClose={handleDrawerToggle}
          ModalProps={{
            keepMounted: true, // Better open performance on mobile.
          }}
          sx={{
            display: { xs: 'block', sm: 'none' },
            '& .MuiDrawer-paper': { boxSizing: 'border-box', width: drawerWidth },
          }}
        >
          {drawer}
        </Drawer>
        <Drawer
          variant="permanent"
          sx={{
            display: { xs: 'none', sm: 'block' },
            '& .MuiDrawer-paper': { boxSizing: 'border-box', width: drawerWidth },
          }}
          open
        >
          {drawer}
        </Drawer>
      </Box>
      <Box
        component="main"
        sx={{ flexGrow: 1, p: 3, width: { sm: `calc(100% - ${drawerWidth}px)` } }}
      >
        <Toolbar />
        {/*<button type="button" onClick={Print} > Print div</button>*/}

        <Box id='printablediv' sx={{ flexGrow: 1 }}>
          <Box sx={sx_MainPanel}>
            <MainRoutes />
          </Box>
          {/*
          <Grid container spacing={2}>
            <Grid xs={12}>
              <Box sx={sx_MainPanel}>
                <MainRoutes />
              </Box>
            </Grid>
            
            <Grid xs={4}>
              <Box sx={sx_AuxPanel}>
                <Typography variant='h2'>
                  Aux Panel
                </Typography>
              </Box>
            </Grid>
            
        </Grid>
        */}

        </Box>
      </Box>
    </Box >
  );
}

const sx_MainPanel = {
  width: 'auto',

  padding: '1cm',

  minWidth: 0,

};

const sx_AuxPanel = {
  width: 'auto',
  height: '28cm',
  backgroundColor: 'WhiteSmoke',
  minWidth: 0,
  ml: '5px'
};

ResponsiveDrawer.propTypes = {
  /**
   * Injected by the documentation to work in an iframe.
   * You won't need it on your project.
   */
  window: PropTypes.func,
};

export default ResponsiveDrawer;