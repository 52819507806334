import * as React from 'react';
import { SendQuery2 } from './ToServer.js';
import { sampleVesselList } from '../Context/SampleData.js';
//DROPDOWN MENU
import MenuItem from '@mui/material/MenuItem';
import Select from '@mui/material/Select';
import FormControl from '@mui/material/FormControl';
import InputLabel from '@mui/material/InputLabel';

/*
COMPONENT: DropMenuVessel
RETURN: a ROW of vessel selected by USER from fetched list (from SQL)

p.header:: optional HEADER
p.updateTrigger:: updateTrigger for the Vessel List (refetch from SQL)
p.setShowDel:: optional prop which may not be passed
p.wage_report_id:: for select FILTER to get the vessels ALREADY added to the TOD
p.setVessel:: sets ROW of vessel fetched from SQL (RETURN)
*/

function DropMenuVesselTOD(p) {
  const [fleet, setFleet] = React.useState([{}]);
  const [defaultValue, setDefaultValue] = React.useState([]);

  const menuList = fleet.map((row, index) => {
    return (
      <MenuItem value={index}>
        {row.name}
      </MenuItem>
    )
  });

  const handleSelect = (e) => {
    p.setVessel(fleet[e.target.value]);
    if (p.setShowDel != undefined)
      p.setShowDel(true);
  }

  React.useEffect(() => {
    const fetchData = async () => {
      const result = await SendQuery2({ header: 'FLEET_LIST_TOD', wageReportID: p.wage_report_id }, "ops.php");

      //for OFFLINE use only
      if (result == "Network Error") {
        setFleet(sampleVesselList);
      } else {
        setFleet(result.data);
      }

    };
    fetchData();
  }, [p.updateTrigger, p.wage_report_id]);

  React.useEffect(() => {
    setDefaultValue([]);
  }, [p.wage_report_id]);

  return (
    <>
      <FormControl variant="standard" sx={{ m: 1, minWidth: 120, float: 'left' }}>
        <InputLabel>Vessels</InputLabel>
        <Select
          labelId="demo-simple-select-standard-label"
          id="demo-simple-select-standard"
          onChange={handleSelect}
          label="Age"
          value={defaultValue}
        >
          {menuList}

        </Select>
      </FormControl>
    </>
  );
}

export default DropMenuVesselTOD;