const sampleCrewList = [
  {
     "id":1,
     "name":"ABDUL HAFIZ BIN ABDUL HAMID",
     "job_title":"CHIEF OFFICER",
     "wage":"220.00"
  },
  {
     "id":2,
     "name":"ABDUL HAMIDIN BIN ABDUL HAMID",
     "job_title":"THIRD ENGINEER",
     "wage":"115.00"
  },
  {
     "id":3,
     "name":"ABDUL SAIDIN BIN ADOMIN",
     "job_title":"SECOND ENGINEER",
     "wage":"220.00"
  },
  {
     "id":4,
     "name":"ABDULLA BIN MAHAMUD",
     "job_title":"THIRD ENGINEER",
     "wage":"115.00"
  },
  {
     "id":5,
     "name":"ABI ROSANTO SURAHJO",
     "job_title":null,
     "wage":"350.00"
  },
  {
     "id":6,
     "name":"ADIE PRAYITNO",
     "job_title":"CHIEF ENGINEER",
     "wage":"300.00"
  },
  {
     "id":7,
     "name":"AERON CHUA YIENG KING",
     "job_title":"ABLE BODIED",
     "wage":"70.00"
  },
  {
     "id":9,
     "name":"AJANG ANYIE WAN",
     "job_title":"MASTER",
     "wage":"435.00"
  },
  {
     "id":10,
     "name":"ALBERT BUSH BIN ALENG",
     "job_title":"CHIEF ENGINEER",
     "wage":"375.00"
  },
  {
     "id":8,
     "name":"ALVIN IVAN ANAK ANCHUM",
     "job_title":"ABLE BODIED",
     "wage":"65.00"
  },
  {
     "id":11,
     "name":"AMIRUDIN BIN TALA",
     "job_title":"SECOND ENGINEER",
     "wage":"220.00"
  },
  {
     "id":12,
     "name":"ARIF BIN YUSOF",
     "job_title":"MASTER",
     "wage":"310.00"
  },
  {
     "id":13,
     "name":"ARIS",
     "job_title":"CHIEF ENGINEER",
     "wage":"300.00"
  },
  {
     "id":14,
     "name":"ARMAN BIN ARIFIN",
     "job_title":"CHIEF OFFICER",
     "wage":"230.00"
  },
  {
     "id":15,
     "name":"ASHAR ASAAD",
     "job_title":"CHIEF ENGINEER",
     "wage":"310.00"
  },
  {
     "id":16,
     "name":"AWANGKU ROSLI BIN PG. YUNUS @ AK. YUNOS",
     "job_title":"MASTER",
     "wage":"310.00"
  },
  {
     "id":17,
     "name":"AZMAN BIN ABDUL RAHMAN",
     "job_title":"ABLE BODIED",
     "wage":"70.00"
  },
  {
     "id":18,
     "name":"BALY NGAU",
     "job_title":"THIRD ENGINEER",
     "wage":"130.00"
  },
  {
     "id":19,
     "name":"BANG BANG ARDIANSJAH BIN IBRAHIM",
     "job_title":"CHIEF ENGINEER",
     "wage":"300.00"
  },
  {
     "id":20,
     "name":"BARYANTO ABDULAH BAJURI",
     "job_title":"CHIEF ENGINEER",
     "wage":"300.00"
  },
  {
     "id":21,
     "name":"BENI BADEN",
     "job_title":"SECOND ENGINEER",
     "wage":"220.00"
  },
  {
     "id":22,
     "name":"BETI ANAK CHUNDANG",
     "job_title":"MASTER",
     "wage":"310.00"
  },
  {
     "id":23,
     "name":"BUHARI",
     "job_title":"SECOND ENGINEER",
     "wage":"220.00"
  },
  {
     "id":24,
     "name":"CLARENCE LAING ANAK LIPA",
     "job_title":"THIRD ENGINEER",
     "wage":"115.00"
  },
  {
     "id":25,
     "name":"DANNY BIN MATURIN",
     "job_title":"CHIEF OFFICER",
     "wage":"220.00"
  },
  {
     "id":26,
     "name":"DENNIS ANAK UNGAN",
     "job_title":"ABLE BODIED",
     "wage":"85.00"
  },
  {
     "id":27,
     "name":"DENNIS NGU",
     "job_title":"ABLE BODIED",
     "wage":"70.00"
  },
  {
     "id":28,
     "name":"DESMOND LIHAN JOK",
     "job_title":"ABLE BODIED",
     "wage":"65.00"
  },
  {
     "id":29,
     "name":"DINO ANAK NIEKREM",
     "job_title":"MASTER",
     "wage":"310.00"
  },
  {
     "id":30,
     "name":"EGY RUNGA",
     "job_title":"MASTER",
     "wage":"310.00"
  },
  {
     "id":31,
     "name":"ERWIN ANAK ANTHONY",
     "job_title":"GREASER",
     "wage":"65.00"
  },
  {
     "id":32,
     "name":"ESANAN BIN KINSUAN",
     "job_title":"ABLE BODIED",
     "wage":"65.00"
  },
  {
     "id":33,
     "name":"FAHRI YANTO KUDDUS",
     "job_title":"CHIEF ENGINEER",
     "wage":"300.00"
  },
  {
     "id":34,
     "name":"FAIRUZ MUNA BIN ZAIDI",
     "job_title":"SECOND ENGINEER",
     "wage":"220.00"
  },
  {
     "id":35,
     "name":"FRANKIE ANAK JOFPERY",
     "job_title":null,
     "wage":"240.00"
  },
  {
     "id":36,
     "name":"FREDDY MULUK ANAK SELUYAH",
     "job_title":"ABLE BODED",
     "wage":"70.00"
  },
  {
     "id":37,
     "name":"GENESIS ANAK SERANG",
     "job_title":"ABLE BODED",
     "wage":"65.00"
  },
  {
     "id":38,
     "name":"GUYANG ANAK INSUL",
     "job_title":"SECOND ENGINEER",
     "wage":"220.00"
  },
  {
     "id":39,
     "name":"HASRULNIZAM BIN JEMAIN",
     "job_title":"ABLE BODIED",
     "wage":"70.00"
  },
  {
     "id":40,
     "name":"HUSSIN BIN HAMIDAT",
     "job_title":"MASTER",
     "wage":"400.00"
  },
  {
     "id":41,
     "name":"ISMAIL BIN SALLEH",
     "job_title":"ABLE BODIED",
     "wage":"65.00"
  },
  {
     "id":42,
     "name":"JA'AFAR BIN JAMBARI",
     "job_title":"CHIEF ENGINEER",
     "wage":"350.00"
  },
  {
     "id":43,
     "name":"JACQUES JAWAN ANAK BEN",
     "job_title":"ABLE BODIED",
     "wage":"65.00"
  },
  {
     "id":44,
     "name":"JARRY ANAK NGALAMBAI",
     "job_title":"SECOND ENGINEER",
     "wage":"220.00"
  },
  {
     "id":45,
     "name":"JEFFERY AK PASANG",
     "job_title":"OILER",
     "wage":"65.00"
  },
  {
     "id":46,
     "name":"JEFFRI BIN EDING",
     "job_title":"THIRD ENGINEER",
     "wage":"115.00"
  },
  {
     "id":47,
     "name":"JEFRI BIN HUSAINI",
     "job_title":"CHIEF ENGINEER",
     "wage":"300.00"
  },
  {
     "id":48,
     "name":"JOHANES DONBOSCO",
     "job_title":"CHIEF ENGINEER",
     "wage":"300.00"
  },
  {
     "id":49,
     "name":"JOHN RAYMOND ANYIE",
     "job_title":"CHIEF OFFICER",
     "wage":"250.00"
  },
  {
     "id":50,
     "name":"JOSEPH ANAK BAJAU",
     "job_title":"ABLE BODIED",
     "wage":"65.00"
  },
  {
     "id":51,
     "name":"JOSEPH ANAK UMAI",
     "job_title":"ABLE BODIED",
     "wage":"65.00"
  },
  {
     "id":52,
     "name":"JUSTINE AMBI ANAK GAYAU",
     "job_title":"THIRD ENGINEER",
     "wage":"115.00"
  },
  {
     "id":53,
     "name":"KAJAMAN ANAK ANTHONY",
     "job_title":null,
     "wage":"70.00"
  },
  {
     "id":54,
     "name":"KELVIN IVAN ANAK DINO",
     "job_title":"ABLE BODIED",
     "wage":"70.00"
  },
  {
     "id":55,
     "name":"KENNY ANAK ENGKECHONG",
     "job_title":"MASTER",
     "wage":"310.00"
  },
  {
     "id":56,
     "name":"KEVIN MORAN ANAK MINON",
     "job_title":"ABLE BODIED",
     "wage":"70.00"
  },
  {
     "id":57,
     "name":"KHAIRUL ARAFAH BIN SIRADJUDDIN",
     "job_title":"ABLE BODIED",
     "wage":"65.00"
  },
  {
     "id":58,
     "name":"KHAIRUL IMBRAN BIN HASSAN",
     "job_title":"ABLE BODIED",
     "wage":"65.00"
  },
  {
     "id":59,
     "name":"KOMARUDIN BIN JAMUD",
     "job_title":null,
     "wage":"240.00"
  },
  {
     "id":60,
     "name":"LAMBERTUS PALIMBU",
     "job_title":"SECOND ENGINEER",
     "wage":"220.00"
  },
  {
     "id":61,
     "name":"LARRY MORRISON ANAK GUP",
     "job_title":"ABLE BODIED",
     "wage":"70.00"
  },
  {
     "id":62,
     "name":"LING SOON HOE",
     "job_title":"THIRD ENGINEER",
     "wage":"220.00"
  },
  {
     "id":63,
     "name":"LU HAN WEE",
     "job_title":"CHIEF OFFICER",
     "wage":"220.00"
  },
  {
     "id":64,
     "name":"MALAMSARI BIN RISAH @ RIASA",
     "job_title":"SECOND ENGINEER",
     "wage":"220.00"
  },
  {
     "id":65,
     "name":"MASRAH BIN AHRISA",
     "job_title":"CHIEF ENGINEER",
     "wage":"330.00"
  },
  {
     "id":66,
     "name":"MERAH BIN BISMAN",
     "job_title":"CHIEF ENGINEER",
     "wage":"300.00"
  },
  {
     "id":67,
     "name":"MHD ZULFADHLI BIN MAHMUD",
     "job_title":"ABLE BODIED",
     "wage":"65.00"
  },
  {
     "id":68,
     "name":"MOHAMMAD AZRUL BIN MAHIDIN",
     "job_title":"ABLE BODIED",
     "wage":"65.00"
  },
  {
     "id":69,
     "name":"MOHAMMAD RIZAL BIN ASLI",
     "job_title":"ABLE BODIED",
     "wage":"65.00"
  },
  {
     "id":70,
     "name":"MOHAMMAD SUFFIAN BIN SPAWI",
     "job_title":"THIRD ENGINEER",
     "wage":"113.00"
  },
  {
     "id":71,
     "name":"MOHD FAUZI BIN JAKARIA",
     "job_title":"ABLE BODIED",
     "wage":"65.00"
  },
  {
     "id":72,
     "name":"MOHD HELMI BIN JAINI",
     "job_title":"CHIEF OFFICER",
     "wage":"220.00"
  },
  {
     "id":73,
     "name":"MOHD NUR MIZAN SYAH BIN SUAIMI",
     "job_title":"ABLE BODIED",
     "wage":"65.00"
  },
  {
     "id":75,
     "name":"MOHD SAIFUL NAZERI BIN ROSLI",
     "job_title":"CHIEF OFFICER",
     "wage":"220.00"
  },
  {
     "id":74,
     "name":"MOHD. JUHAR B. SHARIFF",
     "job_title":"ABLE BODIED",
     "wage":"65.00"
  },
  {
     "id":76,
     "name":"MUHAMMAD FADLEE BIN ABD WASIR",
     "job_title":"SECOND ENGINEER",
     "wage":"220.00"
  },
  {
     "id":77,
     "name":"MYLAND LUMIU",
     "job_title":null,
     "wage":"220.00"
  },
  {
     "id":78,
     "name":"NANANG GUNAWAN",
     "job_title":"SECOND ENGINEER",
     "wage":"220.00"
  },
  {
     "id":79,
     "name":"NELTON ANAK RADIN",
     "job_title":"MASTER",
     "wage":"310.00"
  },
  {
     "id":80,
     "name":"NICHLOS NYANGGAI ANAK UMPIE",
     "job_title":"CHIEF OFFICER",
     "wage":"220.00"
  },
  {
     "id":81,
     "name":"NIVAL SWENGLI SURU",
     "job_title":"CHIEF ENGINEER",
     "wage":"300.00"
  },
  {
     "id":82,
     "name":"NOBER YOHANIS BUNTU",
     "job_title":"CHIEF ENGINEER",
     "wage":"300.00"
  },
  {
     "id":83,
     "name":"NOLDY RONALD LELEH",
     "job_title":"MASTER",
     "wage":"350.00"
  },
  {
     "id":84,
     "name":"NORFAIDZUL BIN ZAIDDI @ BATU",
     "job_title":"MASTER",
     "wage":"310.00"
  },
  {
     "id":85,
     "name":"NORMAN KALONG ANAK BUJANG",
     "job_title":"THIRD OFFICER",
     "wage":"113.00"
  },
  {
     "id":86,
     "name":"NURRIZAL BIN KASSIM",
     "job_title":"CHIEF ENGINEER",
     "wage":"300.00"
  },
  {
     "id":87,
     "name":"NYALIPAN ANAK GANUL",
     "job_title":"SECOND ENGINEER",
     "wage":"240.00"
  },
  {
     "id":88,
     "name":"OMAR BIN LATIP",
     "job_title":"MASTER",
     "wage":"310.00"
  },
  {
     "id":89,
     "name":"PETER ANAK JAROM",
     "job_title":"ABLE BODIED",
     "wage":"65.00"
  },
  {
     "id":90,
     "name":"PHILIP ANAK BALI",
     "job_title":"THIRD ENGINEER",
     "wage":"120.00"
  },
  {
     "id":91,
     "name":"RAJIS BIN YUSOF",
     "job_title":"MASTER",
     "wage":"310.00"
  },
  {
     "id":92,
     "name":"ROGER STEWARD ANAK RAYMOND",
     "job_title":"ABLE BODIED",
     "wage":"70.00"
  },
  {
     "id":93,
     "name":"ROLAND DING",
     "job_title":"THIRD ENGINEER",
     "wage":"170.00"
  },
  {
     "id":94,
     "name":"ROSLAN BIN WAHAB",
     "job_title":"SECOND ENGINEER",
     "wage":"220.00"
  },
  {
     "id":95,
     "name":"ROSMAIDIN BIN ADOMIN",
     "job_title":"ABLE BODIED",
     "wage":"65.00"
  },
  {
     "id":96,
     "name":"RUSLAN BIN AMIR",
     "job_title":"ABLE BODIED",
     "wage":"65.00"
  },
  {
     "id":97,
     "name":"RYAN SAMAN ANAK JUKY",
     "job_title":"ABLE BODIED",
     "wage":"70.00"
  },
  {
     "id":98,
     "name":"SAIFUL BIN MOHAMED",
     "job_title":"ABLE BODIED",
     "wage":"65.00"
  },
  {
     "id":99,
     "name":"SALLEH BIN LAUPA",
     "job_title":"CHIEF ENGINEER",
     "wage":"300.00"
  },
  {
     "id":100,
     "name":"SLAMET RIADI",
     "job_title":"MASTER",
     "wage":"310.00"
  },
  {
     "id":101,
     "name":"SONIE ANAK ANDREW",
     "job_title":"ABLE BODIED",
     "wage":"65.00"
  },
  {
     "id":102,
     "name":"SOPHIAN DAYAN MANGAPE",
     "job_title":"CHIEF OFFICER",
     "wage":"240.00"
  },
  {
     "id":103,
     "name":"STANLEY ANAK MICHEAL",
     "job_title":"OILER",
     "wage":"65.00"
  },
  {
     "id":104,
     "name":"SURYANTO",
     "job_title":"CHIEF ENGINEER",
     "wage":"300.00"
  },
  {
     "id":105,
     "name":"SYAHNAT FUAIN BIN MOHD AMIN",
     "job_title":"SECOND ENGINEER",
     "wage":"220.00"
  },
  {
     "id":106,
     "name":"SYAMSURIJAL",
     "job_title":"MASTER",
     "wage":"310.00"
  },
  {
     "id":107,
     "name":"USMAN BIN ZAILANI",
     "job_title":"MASTER",
     "wage":"310.00"
  },
  {
     "id":108,
     "name":"WALLIS ANAK USIT",
     "job_title":"CHIEF OFFICER",
     "wage":"220.00"
  },
  {
     "id":109,
     "name":"WILYAM BEY PUTRA LAGANTONDO",
     "job_title":"CHIEF OFFICER",
     "wage":"220.00"
  },
  {
     "id":110,
     "name":"YONES PAYUNG ALLO",
     "job_title":"CHIEF ENGINEER",
     "wage":"300.00"
  },
  {
     "id":111,
     "name":"YUSMAN BIN YAKUB",
     "job_title":"CHIEF ENGINEER",
     "wage":"300.00"
  },
  {
     "id":112,
     "name":"YUSRI ABADI",
     "job_title":"MASTER",
     "wage":"310.00"
  },
  {
     "id":113,
     "name":"ZAKERIA BIN BAHTIAR",
     "job_title":"ABLE BODIED",
     "wage":"70.00"
  }
];

export { sampleCrewList }