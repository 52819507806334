import React from "react";
import { SendQuery2 } from '../Component/ToServer.js';
import { sampleAdmin } from './SampleData.js';

const AuthContext = React.createContext({});

export const AuthProvider = ({ children }) => {
  /*const [auth, setAuth] = React.useState({
    "user" : "yongwui",
    "roles" : "admin",
  });*/

  /*const [auth, setAuth] = React.useState({
    role: "not login",

  });*/
  const [auth, setAuth] = React.useState(0);
  const [userData, setUserData] = React.useState({
    username: "",
    role: ""
  });
  const [loginToggle, setLoginToggle] = React.useState(1);

  React.useEffect(() => {
    //setAuth({ ...auth, user: (auth.user+1) });
    /*setAuth(prevState=>{
      return {...prevState, user:auth.user+1};
    });*/
    //setFleet(ps => { return [...ps, { user: 'zxcv'}]}); sample only

    const fetchData = async () => {
      const result = await SendQuery2(userData, "auth.php");
      setUserData(result.data);

      //for OFFLINE use only
      if (result == "Network Error") {
        setUserData(sampleAdmin);
      }

    };
    fetchData();


  }, [loginToggle]);


  return (
    <AuthContext.Provider value={{
      auth, setAuth,
      loginToggle, setLoginToggle,
      userData, setUserData,
    }}>
      {children}
    </AuthContext.Provider>
  );
}

export default AuthContext;