import * as React from 'react';
import './OverviewPayroll.css';

import Typography from '@mui/material/Typography';

import dayjs from 'dayjs';
import ReactToPrint from 'react-to-print';
//MYCOMPONENTS
import DropMenuVesselTOD from '../Component/DropMenuVesselTOD.js';


/*******
 * INPUT: 
 * OverviewTable
 * p.adjustedRow :: Caculated subtotals of overview table
 * p.overview :: whole overview table
 * p.wr_id :: to fetch the vessels related.
 * p.wr_closing_date :: 
 */
function OverviewPayroll(p) {
  const componentRef = React.useRef();
  const [vessel, setVessel] = React.useState({ short_name: 'TMJ' });
  const [updateTrigger, setUpdateTrigger] = React.useState(0);
  let no = 0;
  let lastRow = {
    col11: 0, col12: 0, col13: 0, col14: 0, col15: 0, col16: 0,
    col17: 0, col18: 0, col19: 0, col20: 0, col21: 0, col22: 0
  };
  const toDate = (value) => {
    return (
      dayjs(value * 1000).format("DD-MM-YY")
    );
  };
  const formatter = new Intl.NumberFormat('en-US', {
    style: 'currency',
    currency: 'MYR',
    currencyDisplay: "code"

    // These options are needed to round to whole numbers if that's what you want.
    //minimumFractionDigits: 0, // (this suffices for whole numbers, but will print 2500.10 as $2,500.1)
    //maximumFractionDigits: 0, // (causes 2500.99 to be printed as $2,501)
  });
  const toFormat = (value) => {
    return (
      formatter.format(value).replace("MYR", "").trim()
    );
  };
  function toRound(n) {
    return +(Math.round(n + "e+" + 2) + "e-" + 2);
  }

  const tableData2 = p.overview?.map((crew, index) => {
    let c = crew.vessel.filter(function (vsl) {
      return vsl.short_name === vessel.short_name;
    }).length;
    let isFirst = true;

    return (
      <>
        {(c > 0) &&
          <>
            {
              crew.vessel.map((vRow, i) => {
                if (vRow.short_name === vessel.short_name) {
                  lastRow.col11 = toRound(lastRow.col11 + vRow.salary);
                  lastRow.col12 = toRound(lastRow.col12 + p.adjustedRow[crew.vessel[0].id].col14);
                  lastRow.col13 = toRound(lastRow.col13 + p.adjustedRow[crew.vessel[0].id].col16);
                  lastRow.col14 = toRound(lastRow.col14 + p.adjustedRow[crew.vessel[0].id].col18);
                  lastRow.col15 = toRound(lastRow.col15 + p.adjustedRow[crew.vessel[0].id].col20);
                  lastRow.col16 = toRound(lastRow.col16 + p.adjustedRow[crew.vessel[0].id].col22);
                  lastRow.col17 = toRound(lastRow.col17 + p.adjustedRow[crew.vessel[0].id].col24);
                  lastRow.col18 = toRound(lastRow.col18 + p.adjustedRow[crew.vessel[0].id].col26);
                  lastRow.col19 = toRound(lastRow.col19 + p.adjustedRow[crew.vessel[0].id].col28);
                  lastRow.col20 = toRound(lastRow.col20 + p.adjustedRow[crew.vessel[0].id].col16 + p.adjustedRow[crew.vessel[0].id].col24);
                  lastRow.col21 = toRound(lastRow.col21 + p.adjustedRow[crew.vessel[0].id].col18 + p.adjustedRow[crew.vessel[0].id].col26);
                  lastRow.col22 = toRound(lastRow.col22 + p.adjustedRow[crew.vessel[0].id].col20 + p.adjustedRow[crew.vessel[0].id].col28);

                }
                return (
                  <>
                    {vRow.short_name === vessel.short_name &&
                      <tr className='rowTop'>
                        {(isFirst) &&
                          <>
                            {isFirst = false}

                            <td className='cc1 bLeft' rowspan={c}>{++no}</td>
                            <td className='cc2 bLeft' rowspan={c}>{crew.name}</td>
                            <td className='cc3 bLeft' rowspan={c}>{crew.nationality}</td>
                            <td className='cc4' rowspan={c}>{crew.ic_passport}</td>
                            <td className='cc5' rowspan={c}>{crew.wife_status}</td>

                          </>
                        }
                        <td className='cc6 bLeft'>{toDate(vRow.start_date)}</td>
                        <td className='cc7'>{toDate(vRow.end_date)}</td>
                        <td className='cc8'>{vRow.category}</td>
                        <td className='cc9'>{toFormat(vRow.rate)}</td>
                        <td className='cc10'>{vRow.days}</td>
                        <td className='cc11 bLeft'>{toFormat(vRow.salary)}</td>
                        <td className='cc12 bLeft'>{toFormat(p.adjustedRow[crew.vessel[0].id].col14)}</td>
                        <td className='cc13 bLeft'>{toFormat(p.adjustedRow[crew.vessel[0].id].col16)}</td>
                        <td className='cc14'>{toFormat(p.adjustedRow[crew.vessel[0].id].col18)}</td>
                        <td className='cc15'>{toFormat(p.adjustedRow[crew.vessel[0].id].col20)}</td>
                        <td className='cc16 bLeft'>{toFormat(p.adjustedRow[crew.vessel[0].id].col22)}</td>
                        <td className='cc17 bLeft'>{toFormat(p.adjustedRow[crew.vessel[0].id].col24)}</td>
                        <td className='cc18'>{toFormat(p.adjustedRow[crew.vessel[0].id].col26)}</td>
                        <td className='cc19'>{toFormat(p.adjustedRow[crew.vessel[0].id].col28)}</td>
                        <td className='cc20 bLeft'>{toFormat(p.adjustedRow[crew.vessel[0].id].col16 + p.adjustedRow[crew.vessel[0].id].col24)}</td>
                        <td className='cc21'>{toFormat(p.adjustedRow[crew.vessel[0].id].col18 + p.adjustedRow[crew.vessel[0].id].col26)}</td>
                        <td className='cc22 bRight'>{toFormat(p.adjustedRow[crew.vessel[0].id].col20 + p.adjustedRow[crew.vessel[0].id].col28)}</td>
                      </tr>
                    }

                  </>
                );
              })
            }
          </>

        }
      </>
    );
  });
  /*React.useEffect(() => {

    lastRow = {
      col11: 0, col12: 0, col13: 0, col14: 0, col15: 0, col16: 0,
      col17: 0, col18: 0, col19: 0, col20: 0, col21: 0, col22: 0
    };

  }, [p.wr_id, vessel]);*/
  return (
    <>
      <Typography variant='h4'>Payroll</Typography>
      <DropMenuVesselTOD
        setVessel={setVessel}
        updateTrigger={updateTrigger}
        wage_report_id={p.wr_id}
      />
      <br></br>
      <br></br>
      <br></br>
      <ReactToPrint
        trigger={() => <button>Print this out!</button>}
        content={() => componentRef.current}
      />
      <div ref={componentRef}>
        <table className='overviewPayroll'>
          <thead>
            <tr>
              <th colspan='12'>{vessel.name} - Closing Date: {p.wr_closing_date}</th>
              <th className='myTH' colspan='3'>EMPLOYEE</th>
              <th></th>
              <th className='myTH' colspan='3'>EMPLOYER</th>
              <th className='myTH' colspan='3'>TOTAL</th>
            </tr>
            <tr>
              <th className='myTH'>NO</th>
              <th className='myTH'>NAME</th>
              <th className='myTH'>NAT</th>
              <th className='myTH'>IC/PASSPORT</th>
              <th className='myTH'>PCB CAT</th>
              <th className='myTH'>START</th>
              <th className='myTH'>END</th>
              <th className='myTH'>CAT</th>
              <th className='myTH'>RATES</th>
              <th className='myTH'>DAYS</th>
              <th className='myTH'>SALARY</th>
              <th className='myTH'>PCB</th>
              <th className='myTH'>EPF</th>
              <th className='myTH'>SOCSO</th>
              <th className='myTH'>EIS</th>
              <th className='myTH'>NET SALARY</th>
              <th className='myTH'>EPF</th>
              <th className='myTH'>SOCSO</th>
              <th className='myTH'>EIS</th>
              <th className='myTH'>EPF</th>
              <th className='myTH'>SOCSO</th>
              <th className='myTH'>EIS</th>
            </tr>
          </thead>
          <tbody>
            {tableData2}
            <tr className='rowTop rowBottom'>
              <td className='lastRow bLeft'></td>
              <td className='lastRow bLeft' colspan='9'>TOTAL:</td>
              <td className='lastRow bLeft'>{toFormat(lastRow.col11)}</td>
              <td className='lastRow bLeft'>{toFormat(lastRow.col12)}</td>
              <td className='lastRow bLeft'>{toFormat(lastRow.col13)}</td>
              <td className='lastRow'>{toFormat(lastRow.col14)}</td>
              <td className='lastRow'>{toFormat(lastRow.col15)}</td>
              <td className='lastRow bLeft'>{toFormat(lastRow.col16)}</td>
              <td className='lastRow bLeft'>{toFormat(lastRow.col17)}</td>
              <td className='lastRow'>{toFormat(lastRow.col18)}</td>
              <td className='lastRow'>{toFormat(lastRow.col19)}</td>
              <td className='lastRow bLeft'>{toFormat(lastRow.col20)}</td>
              <td className='lastRow'>{toFormat(lastRow.col21)}</td>
              <td className='lastRow bRight'>{toFormat(lastRow.col22)}</td>
            </tr>
          </tbody>

        </table>
      </div>

    </>
  )
}

export default OverviewPayroll;