import * as React from 'react';
import axios from 'axios';

//DIALOG
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';

//PROP is the ROW selected
function VerifyWageReport(p) {
  const [open, setOpen] = React.useState(false);
  const [data, setData] = React.useState({
    header: "WAGE_REPORT_VERIFY",
    id: p.row.id
  });

  const handleClickOpen = () => {
    setOpen(true);
  };
  const handleClose = () => {
    setOpen(false);
    p.setUpdateList(prev => prev % 2 + 1);
  };
  const handleSubmit = async (e) => {
    try {
      const result = await axios.post('https://ssdejavu.duckdns.org/api/ops.php',
        data,
        {
          headers: {
            'content-type': 'application/json; charset=utf-8',
            'cache-control': 'no-cache',
          }
        });
      //TRIGGER TO UPDATE WORKREPORT table

    } catch (error) {
      console.log(error);
      //ALERT
    }

  }
  return (
    <>
      <Button onClick={handleClickOpen} disabled={p.disabled}>
        VERIFY
      </Button>
      <Dialog
        open={open}
        onClose={handleClose}
      >
        <DialogTitle>
          Verify Wage Report
        </DialogTitle>
        <DialogContent>
          <DialogContentText>
            Please confirm verification of the Wage Report (ID: {p.row.id}) with the closing date <br /><b>{p.row.closing_date}</b>.
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose}>CANCEL</Button>
          <Button onClick={handleClose} autoFocus >
            CONFIRM
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
}
export default VerifyWageReport;