import * as React from 'react';
import axios from 'axios';
import Typography from '@mui/material/Typography';
//DIALOG
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
//DATEPICKER
import dayjs from 'dayjs';
import Stack from '@mui/material/Stack';
import TextField from '@mui/material/TextField';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { DesktopDatePicker } from '@mui/x-date-pickers/DesktopDatePicker';
import Box from '@mui/material/Box';

function AddWageReport(p) {
  const [open, setOpen] = React.useState(false);
  const [msg, setMsg] = React.useState(dayjs(null).unix());
  const [data, setData] = React.useState({});
  const [response, setResponse] = React.useState("to submit");
  const handleClickOpen = () => {
    setOpen(true);
    setData({
      header: "WAGE_REPORT_INSERT",
      opening_date: dayjs(null),
      opening_date_unix: 0,
      closing_date: dayjs(null),
      closing_date_unix: 0,
    });

    setMsg(null);
  };

  const handleClose = () => {
    setOpen(false);
    //p.setUpdateList(prev => prev + 1);
  };
  //const [value, setValue] = React.useState(dayjs('2022-11-01T21:11:54'));
  //in SQL:: FROM_UNIXTIME(1668241914) before inserting into table
  //suggestions:: SELECT DATE_FORMAT(start_date, "%D %M %Y") FROM `wage_report` WHERE 1;
  //value.unix():: converts to unix timestamp when value is dayjs object

  // Install component (community version)
  //npm install @mui/x-date-pickers
  // Install date library (if not already installed)
  //npm install dayjs


  const handleSubmit = async (e) => {

    if (data.opening_date.unix() > 0 && data.closing_date.unix() > 0 && data.closing_date.unix() >= data.opening_date.unix()) {
      try {
        
        const result = await axios.post('https://ssdejavu.duckdns.org/api/ops.php',
          data,
          {
            headers: {
              'content-type': 'application/json; charset=utf-8',
              'cache-control': 'no-cache',
            }
          });
        //TRIGGER TO UPDATE WORKREPORT table
        setResponse(result.data);
        p.setUpdateToken(prev => prev % 2 + 1);
        setOpen(false);
      } catch (error) {
        console.log(error);
        //ALERT
      }
    } else {
      setMsg('Note: Ensure the Opening Date and the Closing Date have been set and that the Closing Date falls after/on the Opening Date.');
    }
    
  };
  const handleChange = (newValue) => {
    setData(prevState => {
      return { ...prevState, opening_date: newValue, opening_date_unix: newValue.unix() };
    });
  };
  const handleChange2 = (newValue) => {
    setData(prevState => {
      return { ...prevState, closing_date: newValue, closing_date_unix: newValue.unix() };
    });
  };
  return (
    <>
      <Button variant="outlined" color="primary" onClick={handleClickOpen}>
        ADD MONTHLY WAGE REPORT
      </Button>
      <Dialog
        open={open}
        onClose={handleClose}
      >
        <DialogTitle>
          ADD MONTHLY WAGE REPORT
        </DialogTitle>
        <DialogContent>
          <DialogContentText>
            Please enter the "Opening Date" and the "Closing Date" of the Wage Report.
          </DialogContentText>
          <Box width='200px' sx={{ pt: '20px', pb: '15px' }}>

            <LocalizationProvider dateAdapter={AdapterDayjs}>
              <Stack spacing={3}>
                <DesktopDatePicker
                  label="Opening Date"
                  inputFormat="DD MMM YYYY"
                  value={data.opening_date}
                  onChange={handleChange}
                  renderInput={(params) => <TextField {...params} />}
                />
                <DesktopDatePicker
                  label="Closing Date"
                  inputFormat="DD MMM YYYY"
                  value={data.closing_date}
                  onChange={handleChange2}
                  renderInput={(params) => <TextField {...params} />}
                />
              </Stack>
            </LocalizationProvider>

          </Box>
          <DialogContentText>
            {msg}
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose}>CANCEL</Button>
          <Button onClick={handleSubmit} autoFocus>
            SUBMIT
          </Button>
        </DialogActions>
      </Dialog>

    </>
  );

}
export default AddWageReport;