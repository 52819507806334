import * as React from 'react';
import axios from 'axios';
import Typography from '@mui/material/Typography';
import Grid from '@mui/material/Unstable_Grid2';
import Box from '@mui/material/Box';

//AUTO COMPLETE
import Autocomplete from '@mui/material/Autocomplete';

//DIALOG
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';

//DATEPICKER
import dayjs from 'dayjs';
import Stack from '@mui/material/Stack';
import TextField from '@mui/material/TextField';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { DesktopDatePicker } from '@mui/x-date-pickers/DesktopDatePicker';


/*
COMPONENT: TODCrewAdd
RETURN: Inserts a single crew TOD into DATABASE

p.handleUpdate():: triggers the Parent component to update table.
p.todID:: tod_id of JSON row selected by user 
p.wageReport:: selected wageReprot JSON row for the purpose of open/close date



NOTE: UPDATE TOD TABLE'S PREPARED BY AND DATE.
*/


function TODCrewAdd(p) {
  const [open, setOpen] = React.useState(false);
  const [data, setData] = React.useState({ header: "TOD_CREW_ADD" });
  const [msg, setMsg] = React.useState("");
  const [wageRef, setWageRef] = React.useState("");
  const categoryInput = React.useRef(null);
  const rateInput = React.useRef(null);
  const defaultProps = {
    options: p.crewList,
    getOptionLabel: (option) => option.name + " *** " + option.job_title,
  };

  const handleClickOpen = () => {
    setOpen(true);
    setData({
      header: "TOD_CREW_ADD",
      wage_report_id: p.wageReport.id,
      tod_id: p.tod_id,
      crew_id: null,
      start_date: dayjs(p.wageReport.opening_date),
      start_date_unix: dayjs(p.wageReport.opening_date).unix(),
      end_date: dayjs(p.wageReport.closing_date),
      end_date_unix: dayjs(p.wageReport.closing_date).unix(),
      sign_on: dayjs(null),
      sign_on_unix: null,
      sign_off: dayjs(null),
      sign_off_unix: null,
      rate: '',
      category: ''
    });
    setWageRef("");
    setMsg("");
  };

  const handleClose = () => {
    setOpen(false);
  };
  const handleStartDateChange = (newValue) => {
    setData(prevState => {
      return { ...prevState, start_date: newValue, start_date_unix: newValue.unix() };
    });
  };
  const handleEndDateChange = (newValue) => {
    setData(prevState => {
      return { ...prevState, end_date: newValue, end_date_unix: newValue.unix() };
    });
  };

  const handleSignOnDateChange = (newValue) => {
    setData(prevState => {
      return { ...prevState, sign_on: newValue, sign_on_unix: newValue.unix() };
    });
  };
  const handleSignOffDateChange = (newValue) => {
    setData(prevState => {
      return { ...prevState, sign_off: newValue, sign_off_unix: newValue.unix() };
    });
  };
  //returns the selected ROW.
  const handleSelectChange = (e, value) => {
    setData(prevState => {
      return { ...prevState, crew_id: value.id };
    });
    setWageRef(value.wage);
  };

  const handleRateChange = (e) => {
    const name = e.target.name;
    setData(prevState => {
      return { ...prevState, [name]: e.target.value };
    });
  };

  const handleSubmitClick = () => {
    if (data.start_date_unix == 0 || data.end_date_unix == 0 || data.crew_id == null) {
      setMsg('Alert: Crew, StartDate and EndDate cannot be empty.');
    } else if ((data.category != '' && data.rate == '') || (data.category == '' && data.rate != '')) {
      setMsg('Alert: Both Category and Rates fields has to be either empty or filled.');
    } else {
      /* if (data.rate == null){
         setData(prevState => {
           return { ...prevState, rate: wageRef, category: 'Daily' };
         });
       }*/
      handleSubmit();
      handleClose();

    }

  };

  const handleSubmit = async (e) => {
    try {
      const result = await axios.post('https://ssdejavu.duckdns.org/api/ops.php',
        data,
        {
          headers: {
            'content-type': 'application/json; charset=utf-8',
            'cache-control': 'no-cache',
          }
        });
        p.handleUpdate();
      //setMsg(result.data); 
      /*result.data returns 
      0 :: Vessel is not added into TOD
      due to INSERT IGNORE as compound vessel_id & wr_id is not unique
      1 :: Operation is sucessful
        */
      //TRIGGER TO UPDATE WORKREPORT table

    } catch (error) {
      console.log(error);
      //ALERT
    }
  };

  return (
    <>
      <Button disabled={p.disabled} variant="outlined" color="primary" onClick={handleClickOpen}>
        ADD CREW TOD
      </Button>
      <Dialog
        open={open}
        onClose={handleClose}
      >
        <DialogTitle>
          Tour Of Duty : Add Crew Entry
        </DialogTitle>
        <DialogContent>
          <DialogContentText>
            Please fill in the forms below.
            <Typography color='brown'>{msg}</Typography>
          </DialogContentText>
          <Autocomplete
            disableClearable
            onChange={handleSelectChange}
            {...defaultProps}
            sx={{ width: 400 }}
            renderInput={(params) => (
              <TextField {...params} label="Crews" variant="standard" />
            )}
          />
          <Box width='550px' sx={{ pt: '20px', pb: '15px' }}>
            <LocalizationProvider dateAdapter={AdapterDayjs}>
              <Box width='200px' sx={{ float: 'left', m: '10px' }}>
                <DesktopDatePicker
                  label="Start Date"
                  inputFormat="DD MMM YYYY"
                  value={data.start_date}
                  onChange={handleStartDateChange}
                  renderInput={(params) => <TextField {...params} />}
                />
              </Box>
              <Box width='200px' sx={{ float: 'left', m: '10px' }}>
                <DesktopDatePicker
                  label="End Date"
                  inputFormat="DD MMM YYYY"
                  value={data.end_date}
                  onChange={handleEndDateChange}
                  renderInput={(params) => <TextField {...params} />}
                />
              </Box>

              <Box width='200px' sx={{ float: 'left', m: '10px' }}>
                <DesktopDatePicker
                  label="Sign On Date"
                  inputFormat="DD MMM YYYY"
                  value={data.sign_on}
                  onChange={handleSignOnDateChange}
                  renderInput={(params) => <TextField {...params} />}
                />
              </Box>
              <Box width='200px' sx={{ float: 'left', m: '10px' }}>
                <DesktopDatePicker
                  label="Sign Off Date"
                  inputFormat="DD MMM YYYY"
                  value={data.sign_off}
                  onChange={handleSignOffDateChange}
                  renderInput={(params) => <TextField {...params} />}
                />
              </Box>
            </LocalizationProvider>
            <Box sx={{ float: 'left', m: '5px' }}>
              <Typography color='gray' > Stardard Daily Rate : <u>{wageRef}</u></Typography>
              <Typography color='gray' > Fill in both boxes below if other Rate applies. Eg. Standby, Laid Up etc</Typography>
              <TextField
                disabled={false}
                autoComplete='off'
                label="Category"
                name='category'
                variant="standard"
                inputRef={categoryInput}
                sx={{ m: '10px' }}
                onChange={handleRateChange}
              />
              <TextField
                disabled={false}
                autoComplete='off'
                label="Rates"
                name='rate'
                variant="standard"
                type='number'
                inputRef={rateInput}
                sx={{ m: '10px' }}
                onChange={handleRateChange}
              />
            </Box>
          </Box>
        </DialogContent>

        <DialogActions>
          <Button onClick={handleClose}>CANCEL</Button>
          <Button onClick={handleSubmitClick} autoFocus>
            SUBMIT
          </Button>
        </DialogActions>
      </Dialog>

    </>
  );
}

export default TODCrewAdd; 