import * as React from 'react';
import axios from 'axios';
import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';
//DIALOG
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
//MY COMPONENTS
import DropMenuVessel from '../Component/DropMenuVessel.js';

/*COMPONENT:
Adds VESSEL into TOD table.

p.wage_report_id:: user selected wage report's ID
p.handleUpdate:: trigger to refresh parent table
p.disabled:: disables button if conditions not met. Eg. p.wageReport not selected in parent.
*/
function TODVesselAdd(p) {
  const [open, setOpen] = React.useState(false);
  const [vessel, setVessel] = React.useState({ id: -1 });
  const [disableButton, setDisableButton] = React.useState(true);
  //const [msg, setMsg] = React.useState("default");
  const [data, setData] = React.useState({});
  //intialization
  const handleClickOpen = () => {
    setOpen(true);
    setDisableButton(true);
    setData({
      header: 'TOD_ADD_VESSEL',
      wage_report_id: null,
      vessel_id: null,
    });
    setVessel(-1);
    setDisableButton(true);
  };
  const handleClose = () => {
    setOpen(false);
    p.handleUpdate();
    //p.setUpdateList(prev => prev + 1);
  };

  const handleSubmit = async (e) => {
    try {
      const result = await axios.post('https://ssdejavu.duckdns.org/api/ops.php',
        data,
        {
          headers: {
            'content-type': 'application/json; charset=utf-8',
            'cache-control': 'no-cache',
          }
        });
      handleClose();
      //setMsg(result.data); 
      /*result.data returns 
      0 :: Vessel is not added into TOD
      due to INSERT IGNORE as compound vessel_id & wr_id is not unique
      1 :: Operation is sucessful
        */
      //TRIGGER TO UPDATE WORKREPORT table

    } catch (error) {
      console.log(error);
      //ALERT
    }
  }

  React.useEffect(() => {
    setData(prevState => {
      return { ...prevState, wage_report_id: p.wage_report_id, vessel_id: vessel.id };
    });
    //Vessel is set to -1 when OPEN.
    //disables everytime ADDVESSEL is opened. See initialization in handleClickOpen
    (vessel < 0)
      ? setDisableButton(true)
      : setDisableButton(false)

  }, [vessel]);


  return (
    <>
      <Button variant="outlined" onClick={handleClickOpen} disabled={p.disabled}>
        ADD VESSEL
      </Button>
      <Dialog
        open={open}
        onClose={handleClose}
      >
        <DialogTitle>
          Wage Report: Add Vessel
        </DialogTitle>
        <DialogContent>
          <DialogContentText>
            Please select the "Wage Report" to be deleted.
          </DialogContentText>
          <DropMenuVessel setVessel={setVessel} />
          <DialogContentText>

          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose}>CANCEL</Button>
          <Button onClick={handleSubmit} autoFocus disabled={disableButton}>
            ADD VESSEL
          </Button>
        </DialogActions>
      </Dialog>

    </>
  );

}
export default TODVesselAdd;