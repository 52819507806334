import * as React from 'react';
import { SendQuery2 } from './ToServer.js';
import { sampleVesselList } from '../Context/SampleData.js';
//DROPDOWN MENU
import MenuItem from '@mui/material/MenuItem';
import Select from '@mui/material/Select';
import FormControl from '@mui/material/FormControl';
import InputLabel from '@mui/material/InputLabel';

/*
COMPONENT: DropMenuVessel
RETURN: a ROW of vessel selected by USER from fetched list (from SQL)

p.header:: optional HEADER
p.updateTrigger:: updateTrigger for the Vessel List (refetch from SQL)
p.setShowDel:: optional prop which may not be passed
p.setVessel:: sets ROW of vessel fetched from SQL (RETURN)
*/

function DropMenuVessel(p) {
  const data = { header: 'FLEET_LIST' };

  const [fleet, setFleet] = React.useState([{}]);
  const menuList = Object.entries(fleet).map((row) => {
    return (
      <MenuItem value={row[0]}>
        {row[1].name}
      </MenuItem>
    )
  });

  const handleSelect = (e) => {
    p.setVessel(fleet[e.target.value]);

    if (p.setShowDel != undefined)
      p.setShowDel(true);
  }

  React.useEffect(() => {
    const fetchData = async () => {
      const result = await SendQuery2(data, "ops.php");

      //for OFFLINE use only
      if (result == "Network Error") {
        setFleet(sampleVesselList);
      } else {
        setFleet(result.data);
      }

    };
    fetchData();
  }, [p.updateTrigger]);
  return (
    <FormControl variant="standard" sx={{ m: 1, minWidth: 120, float: 'left' }}>
      <InputLabel>Vessels</InputLabel>
      <Select
        labelId="demo-simple-select-standard-label"
        id="demo-simple-select-standard"
        onChange={handleSelect}
        label="Age"
      >
        {menuList}

      </Select>
    </FormControl>
  );
}

export default DropMenuVessel;