import axios from 'axios';

function SendQuery(data) {
  return axios({
    method: 'post',
    url: 'https://ssdejavu.duckdns.org/api/login.php',
    headers: {
      'content-type': 'application/json',
      /*'Access-Control-Allow-Origin': '*',
      'Access-Control-Allow-Headers': '*',
      */
    },
    data: data,
  })
    .then(function (response) {
      return response.data;
      //console.log(response);

    })
    .catch(function (error) {
      //set_data(JSON.stringify(error.message));
      return error.message;
      //console.log(error);
    });

}

function SendQuery2(data, phpFile) {
  return axios({
    method: 'post',
    url: 'https://ssdejavu.duckdns.org/api/' + phpFile,
    headers: {
      'content-type': 'application/json; charset=utf-8',
      'cache-control': 'no-cache',
      /*'Access-Control-Allow-Origin': '*',
      'Access-Control-Allow-Headers': '*',
      */
    },
    data: data,
  })
    .then(function (response) {
      console.log(response);
      return response;


    })
    .catch(function (error) {
      //set_data(JSON.stringify(error.message));
      console.log(error);
      return error.message;

    });

}

async function SendQuery3(data, phpFile) {
  try {
    const response = await axios({
      method: 'post',
      url: 'https://ssdejavu.duckdns.org/api/' + phpFile,
      headers: {
        'content-type': 'application/json; charset=utf-8',
        'cache-control': 'no-cache',
      },
      data: data,
    });
    console.log(response);
    return response;
  } catch (error) {
    //set_data(JSON.stringify(error.message));
    console.log(error);
    return error.message;
  }

}

export { SendQuery, SendQuery2, SendQuery3 }