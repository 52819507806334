import * as React from 'react';
import axios from 'axios';
import './OverviewTable.css';
import { sampleOverviewMT } from '../Context/SampleOverview.js';
import dayjs from 'dayjs';
import ReactToPrint from 'react-to-print';

//MYCOMPONENTS
import OverviewPayroll from '../Calculator/OverviewPayroll';
import Typography from '@mui/material/Typography';

/****
 * 
 * INPUT:
 * p.wr_id:: work_report_id
 * updateTrigger:: trigger to fetch update from SQL
 */



function OverviewTable(p) {
  const [overview, setOverview] = React.useState(sampleOverviewMT);

  let adjustedRow = {};
  //let cc = 0;
  let lastRow = {
    col12: 0, col13: 0, col14: 0, col15: 0, col16: 0, col17: 0,
    col18: 0, col19: 0, col20: 0, col21: 0, col22: 0, col23: 0, col24: 0,
    col25: 0, col26: 0, col27: 0, col28: 0, col29: 0, col30: 0, col31: 0,
    col32: 0
  };
  let temp = {
    col14: 0, col16: 0, col18: 0, col20: 0,
    col22: 0, col24: 0, col26: 0, col28: 0
  };
  let temp_startingTotal = {
    col14: 0, col16: 0, col18: 0, col20: 0,
    col22: 0, col24: 0, col26: 0, col28: 0
  };

  const formatter = new Intl.NumberFormat('en-US', {
    style: 'currency',
    currency: 'MYR',
    currencyDisplay: "code"

    // These options are needed to round to whole numbers if that's what you want.
    //minimumFractionDigits: 0, // (this suffices for whole numbers, but will print 2500.10 as $2,500.1)
    //maximumFractionDigits: 0, // (causes 2500.99 to be printed as $2,501)
  });

  const toFormat = (value) => {
    return (
      formatter.format(value).replace("MYR", "").trim()
    );
  };

  const toDate = (value) => {
    return (
      dayjs(value * 1000).format("DD-MM-YY")
    );
  };

  function toRound(n, place) {
    return +(Math.round(n + "e+" + place) + "e-" + place);
  }


  const tableData = overview?.map((row, index) => {
    let r = row.vessel.length;
    temp_startingTotal = {
      col14: lastRow.col14, col16: lastRow.col16, col18: lastRow.col18, col20: lastRow.col20,
      col22: lastRow.col22, col24: lastRow.col24, col26: lastRow.col26, col28: lastRow.col28

    };

    lastRow.col12 = lastRow.col12 + row.vessel[0].salary;
    lastRow.col13 = lastRow.col13 + row.vessel[0].total_salary;
    temp.col14 = toRound(row.vessel[0].total_pcb * row.vessel[0].ratio, 2); // PCB
    lastRow.col14 = lastRow.col14 + temp.col14;
    lastRow.col15 = lastRow.col15 + row.vessel[0].total_pcb;
    //EMPLOYEE PAID STATUTORIES
    temp.col16 = toRound(row.vessel[0].employee_epf * row.vessel[0].ratio, 2);
    lastRow.col16 = lastRow.col16 + temp.col16;
    lastRow.col17 = lastRow.col17 + row.vessel[0].employee_epf;
    temp.col18 = toRound(row.vessel[0].employee_socso * row.vessel[0].ratio, 2);
    lastRow.col18 = lastRow.col18 + temp.col18;
    lastRow.col19 = lastRow.col19 + row.vessel[0].employee_socso;
    temp.col20 = toRound(row.vessel[0].employee_eis * row.vessel[0].ratio, 2);
    lastRow.col20 = lastRow.col20 + temp.col20;
    lastRow.col21 = lastRow.col21 + row.vessel[0].employee_eis;
    //NET SALARY
    //let tmp = (row.vessel[0].total_pcb + row.vessel[0].employee_epf + row.vessel[0].employee_socso + row.vessel[0].employee_eis) * row.vessel[0].ratio

    temp.col22 = row.vessel[0].salary - (toRound(temp.col14, 2) + toRound(temp.col16, 2) + toRound(temp.col18, 2) + toRound(temp.col20, 2));
    lastRow.col22 = lastRow.col22 + (toRound(temp.col22, 2));
    lastRow.col23 = lastRow.col23 + (row.vessel[0].total_salary - (row.vessel[0].total_pcb + row.vessel[0].employee_epf + row.vessel[0].employee_socso + row.vessel[0].employee_eis));
    //EMPLOYER PAID STATUTORIES
    temp.col24 = toRound(row.vessel[0].employer_epf * row.vessel[0].ratio, 2)
    lastRow.col24 = lastRow.col24 + temp.col24;
    lastRow.col25 = lastRow.col25 + row.vessel[0].employer_epf;
    temp.col26 = toRound(row.vessel[0].employer_socso * row.vessel[0].ratio, 2)
    lastRow.col26 = lastRow.col26 + temp.col26;
    lastRow.col27 = lastRow.col27 + row.vessel[0].employer_socso;
    temp.col28 = toRound(row.vessel[0].employer_eis * row.vessel[0].ratio, 2)
    lastRow.col28 = lastRow.col28 + temp.col28;
    lastRow.col29 = lastRow.col29 + row.vessel[0].employer_eis;
    //TOTAL STATUTORIES
    lastRow.col30 = lastRow.col30 + row.vessel[0].employer_epf + row.vessel[0].employee_epf;
    lastRow.col31 = lastRow.col31 + row.vessel[0].employer_socso + row.vessel[0].employee_socso;
    lastRow.col32 = lastRow.col32 + row.vessel[0].employer_eis + row.vessel[0].employee_eis;
    adjustedRow = {
      ...adjustedRow, [row.vessel[0].id]: {
        col14: temp.col14,
        col16: temp.col16,
        col18: temp.col18,
        col20: temp.col20,
        col22: temp.col22,
        col24: temp.col24,
        col26: temp.col26,
        col28: temp.col28,
      }
    };
    //console.log(row.vessel[0].id); cc++; console.log(cc + '<');
    return (
      <>
        <tr className='row'>
          <td className='c1 bLeft' rowspan={r}>{index + 1}</td>
          <td className='c2 bLeft' rowspan={r}>{row.name}</td>
          <td className='c3 bLeft' rowspan={r}>{row.nationality}</td>
          <td className='c4' rowspan={r}>{row.ic_passport}</td>
          <td className='c5 bRight' rowspan={r}>{row.wife_status}</td>

          <td className='c6'>{row.vessel[0].short_name}</td>
          <td className='c7 bLeft'>{toDate(row.vessel[0].start_date)}</td>
          <td className='c8'>{toDate(row.vessel[0].end_date)}</td>
          <td className='c9'>{row.vessel[0].category}</td>
          <td className='c10'>{toFormat(row.vessel[0].rate)}</td>
          <td className='c11'>{row.vessel[0].days}</td>
          <td className='c12 bLeft'>{toFormat(row.vessel[0].salary)}</td>
          <td className='c13 '>{toFormat(row.vessel[0].total_salary)}</td>
          <td className='c14 bLeft'>{toFormat(temp.col14)}</td>
          <td className='c15'>{toFormat(row.vessel[0].total_pcb)}</td>
          <td className='c16 bLeft'>{toFormat(temp.col16)}</td>
          <td className='c17 '>{toFormat(row.vessel[0].employee_epf)}</td>
          <td className='c18 bLeft'>{toFormat(temp.col18)}</td>
          <td className='c19'>{toFormat(row.vessel[0].employee_socso)}</td>
          <td className='c20 bLeft'>{toFormat(temp.col20)}</td>
          <td className='c21 '>{toFormat(row.vessel[0].employee_eis)}</td>
          <td className='c22 dbLeft'>{toFormat(temp.col22)}</td>
          <td className='c23'>{toFormat(row.vessel[0].total_salary - (row.vessel[0].total_pcb + row.vessel[0].employee_epf + row.vessel[0].employee_socso + row.vessel[0].employee_eis))}</td>
          <td className='c24 bLeft'>{toFormat(temp.col24)}</td>
          <td className='c25'>{toFormat(row.vessel[0].employer_epf)}</td>
          <td className='c26 bLeft'>{toFormat(temp.col26)}</td>
          <td className='c27'>{toFormat(row.vessel[0].employer_socso)}</td>
          <td className='c28 bLeft'>{toFormat(temp.col28)}</td>
          <td className='c29'>{toFormat(row.vessel[0].employer_eis)}</td>
          <td className='c30 bLeft'>{toFormat(row.vessel[0].employer_epf + row.vessel[0].employee_epf)}</td>
          <td className='c31'>{toFormat(row.vessel[0].employer_socso + row.vessel[0].employee_socso)}</td>
          <td className='c32 bRight'>{toFormat(row.vessel[0].employer_eis + row.vessel[0].employee_eis)}</td>
        </tr>
        {
          row.vessel.map((nRow, i) => {
            let vName = nRow.short_name;
            //let vSalaryTotal = nRow.total_salary;
            if (i > 0) {
              if (nRow.short_name === row.vessel[i - 1].short_name) {
                vName = "";
              }
              if (i < row.vessel.length - 1) {
                lastRow.col12 = lastRow.col12 + nRow.salary;
                temp.col14 = toRound(nRow.total_pcb * nRow.ratio, 2);
                lastRow.col14 = toRound(lastRow.col14 + temp.col14, 2);
                //EMPLOYEE PAID STATUTORY
                temp.col16 = toRound(nRow.employee_epf * nRow.ratio, 2);
                lastRow.col16 = toRound(lastRow.col16 + temp.col16, 2);
                temp.col18 = toRound(nRow.employee_socso * nRow.ratio, 2);
                lastRow.col18 = toRound(lastRow.col18 + temp.col18, 2);
                temp.col20 = toRound(nRow.employee_eis * nRow.ratio, 2);
                lastRow.col20 = toRound(lastRow.col20 + temp.col20, 2);
                //NET SALARY
                //temp.col22 = (nRow.salary - (nRow.total_pcb + nRow.employee_epf + nRow.employee_socso + nRow.employee_eis) * nRow.ratio);
                temp.col22 = toRound(nRow.salary - toRound(temp.col14, 2) - toRound(temp.col16, 2) - toRound(temp.col18, 2) - toRound(temp.col20, 2), 2);
                lastRow.col22 = lastRow.col22 + toRound(temp.col22, 2);
                //EMPLOYER PAID STATUTORY
                temp.col24 = toRound(nRow.employer_epf * nRow.ratio, 2);
                lastRow.col24 = toRound(lastRow.col24 + temp.col24, 2);
                temp.col26 = toRound(nRow.employer_socso * nRow.ratio, 2);
                lastRow.col26 = toRound(lastRow.col26 + temp.col26, 2);
                temp.col28 = toRound(nRow.employer_eis * nRow.ratio, 2);
                lastRow.col28 = toRound(lastRow.col28 + temp.col28, 2);
                console.log(nRow.id);
                adjustedRow = {
                  ...adjustedRow, [nRow.id]: {
                    col14: temp.col14,
                    col16: temp.col16,
                    col18: temp.col18,
                    col20: temp.col20,
                    col22: temp.col22,
                    col24: temp.col24,
                    col26: temp.col26,
                    col28: temp.col28,
                  }
                };
              } else { //final ROW to ROUND UP the decimals
                lastRow.col12 = lastRow.col12 + nRow.salary;
                temp.col14 = toRound(row.vessel[0].total_pcb - (lastRow.col14 - temp_startingTotal.col14), 2);
                lastRow.col14 = toRound(lastRow.col14 + temp.col14, 2);
                //EMPLOYEE PAID STATUTORY
                temp.col16 = toRound(row.vessel[0].employee_epf - (lastRow.col16 - temp_startingTotal.col16), 2);
                lastRow.col16 = toRound(lastRow.col16 + temp.col16, 2);
                temp.col18 = toRound(row.vessel[0].employee_socso - (lastRow.col18 - temp_startingTotal.col18), 2);
                lastRow.col18 = toRound(lastRow.col18 + temp.col18, 2);
                temp.col20 = toRound(row.vessel[0].employee_eis - (lastRow.col20 - temp_startingTotal.col20), 2);
                lastRow.col20 = toRound(lastRow.col20 + temp.col20, 2);
                //NET SALARY
                //temp.col22 = (nRow.salary - (nRow.total_pcb + nRow.employee_epf + nRow.employee_socso + nRow.employee_eis) * nRow.ratio); 
                temp.col22 = toRound(nRow.salary - toRound(temp.col14, 2) - toRound(temp.col16, 2) - toRound(temp.col18, 2) - toRound(temp.col20, 2), 2);
                lastRow.col22 = lastRow.col22 + toRound(temp.col22, 2);
                //EMPLOYER PAID STATUTORY
                temp.col24 = toRound(row.vessel[0].employer_epf - (lastRow.col24 - temp_startingTotal.col24), 2);
                lastRow.col24 = toRound(lastRow.col24 + temp.col24, 2);
                temp.col26 = toRound(row.vessel[0].employer_socso - (lastRow.col26 - temp_startingTotal.col26), 2);
                lastRow.col26 = toRound(lastRow.col26 + temp.col26, 2);
                temp.col28 = toRound(row.vessel[0].employer_eis - (lastRow.col28 - temp_startingTotal.col28), 2);
                lastRow.col28 = toRound(lastRow.col28 + temp.col28, 2);
                console.log(nRow.id);
                adjustedRow = {
                  ...adjustedRow, [nRow.id]: {
                    col14: temp.col14,
                    col16: temp.col16,
                    col18: temp.col18,
                    col20: temp.col20,
                    col22: temp.col22,
                    col24: temp.col24,
                    col26: temp.col26,
                    col28: temp.col28,
                  }
                }; //cc++;
              }
            }
            return (
              <>
                {
                  (i > 0) &&
                  <tr>
                    <td className='c6'>{vName}</td>
                    <td className='c7 bLeft'>{toDate(nRow.start_date)}</td>
                    <td className='c8'>{toDate(nRow.end_date)}</td>
                    <td className='c9'>{nRow.category}</td>
                    <td className='c10'>{toFormat(nRow.rate)}</td>
                    <td className='c11'>{nRow.days}</td>
                    <td className='c12 bLeft'>{toFormat(nRow.salary)}</td>
                    <td className='c13'></td>
                    <td className='c14 bLeft'>{toFormat(temp.col14)}</td>
                    <td className='c15'></td>
                    <td className='c16 bLeft'>{toFormat(temp.col16)}</td>
                    <td className='c17'></td>
                    <td className='c18 bLeft'>{toFormat(temp.col18)}</td>
                    <td className='c19'></td>
                    <td className='c20 bLeft'>{toFormat(temp.col20)}</td>
                    <td className='c21'></td>
                    <td className='c22 dbLeft'>{toFormat(temp.col22)}</td>
                    <td className='c23'></td>
                    <td className='c24 bLeft'>{toFormat(temp.col24)}</td>
                    <td className='c25'></td>
                    <td className='c26 bLeft'>{toFormat(temp.col26)}</td>
                    <td className='c27'></td>
                    <td className='c28 bLeft'>{toFormat(temp.col28)}</td>
                    <td className='c29'></td>
                    <td className='c30 bLeft'></td>
                    <td className='c31'></td>
                    <td className='c32 bRight'></td>

                  </tr>
                }
              </>
            );
          })
        }

      </>
    );
  });

  React.useEffect(() => {
    const fetch = async (e) => {
      try {
        const result = await axios.post('https://ssdejavu.duckdns.org/api/ops.php',
          { header: 'OVERVIEW_TABLE', wage_report_id: p.wr_id },
          {
            headers: {
              'content-type': 'application/json; charset=utf-8',
              'cache-control': 'no-cache',
            }
          });
        setOverview(result.data);

      } catch (error) {
        //setOverview({ MSG: 'EMPTY' });
        console.log(error);
        //ALERT
      }
    }
    fetch();
    /*lastRow = {
      col12: 0, col13: 0, col14: 0, col15: 0, col16: 0, col17: 0,
      col18: 0, col19: 0, col20: 0, col21: 0, col22: 0, col23: 0, col24: 0,
      col25: 0, col26: 0, col27: 0, col28: 0, col29: 0, col30: 0, col31: 0,
      col32: 0
    };
    temp = {
      col14: 0, col16: 0, col18: 0, col20: 0,
      col22: 0, col24: 0, col26: 0, col28: 0
    };*/

  }, [p.wr_id]);
  const componentRef = React.useRef();
  return (
    <>
      <ReactToPrint
        trigger={() => <button>Print this out!</button>}
        content={() => componentRef.current}
      />
      <div ref={componentRef} className='printTest'>
        <Typography variant='subtitle2' align='left'>Wage Overview - Closing Date: {p.wr_closing_date}</Typography>
        <table id='myTable' className='overviewTable'>
          <thead>
            <tr>
              <th rowspan='3' className='tAlign'>NO</th>
              <th rowspan='3' className='tAlign'>NAME</th>
              <th rowspan='3' className='tAlign'>NAT</th>
              <th rowspan='3' className='tAlign'>IC/PASSPORT</th>
              <th rowspan='3' className='tAlign'>PCB CAT</th>
              <th rowspan='3' className='tAlign'>VSL</th>
              <th rowspan='3' className='tAlign'>START</th>
              <th rowspan='3' className='tAlign'>END</th>
              <th rowspan='3' className='tAlign'>CATEGORY</th>
              <th rowspan='3' className='tAlign'>RATES</th>
              <th rowspan='3' className='tAlign'>NO OF DAYS</th>
              <th rowspan='2' colspan='2' className='tAlign'>SALARY</th>
              <th rowspan='2' colspan='2' className='tAlign'>PCB</th>
              <th colspan='6' className='tAlign'>EMPLOYEE</th>
              <th rowspan='2' colspan='2' className='tAlign dbLeft'>NET SALARY</th>
              <th colspan='6' className='tAlign'>EMPLOYER</th>
              <th rowspan='2' colspan='3' className='tAlign'>TOTAL</th>
            </tr>
            <tr>
              <th colspan='2' className='tAlign'>EPF</th>
              <th colspan='2' className='tAlign'>SOCSO</th>
              <th colspan='2' className='tAlign'>EIS</th>
              <th colspan='2' className='tAlign'>EPF</th>
              <th colspan='2' className='tAlign'>SOCSO</th>
              <th colspan='2' className='tAlign'>EIS</th>
            </tr>
            <tr>
              <th className='tAlign'>BY VSL</th>
              <th className='tAlign'>TOTAL</th>
              <th className='tAlign'>BY VSL</th>
              <th className='tAlign'>TOTAL</th>
              <th className='tAlign'>BY VSL</th>
              <th className='tAlign'>TOTAL</th>
              <th className='tAlign'>BY VSL</th>
              <th className='tAlign'>TOTAL</th>
              <th className='tAlign'>BY VSL</th>
              <th className='tAlign'>TOTAL</th>
              <th className='tAlign dbLeft'>BY VSL</th>
              <th className='tAlign'>TOTAL</th>
              <th className='tAlign'>BY VSL</th>
              <th className='tAlign'>TOTAL</th>
              <th className='tAlign'>BY VSL</th>
              <th className='tAlign'>TOTAL</th>
              <th className='tAlign'>BY VSL</th>
              <th className='tAlign'>TOTAL</th>
              <th className='tAlign'>EPF</th>
              <th className='tAlign'>SOCSO</th>
              <th className='tAlign'>EIS</th>
            </tr>
          </thead>
          <tbody>
            {tableData}
            <tr className='row rowBottom'>
              <td className='bLeft'></td>
              <td className='bLeft' colspan='10'>TOTAL</td>
              <td className='bLeft c12'>{toFormat(lastRow.col12)}</td>
              <td className='c13'>{toFormat(lastRow.col13)}</td>
              <td className='bLeft c14'>{toFormat(lastRow.col14)}</td>
              <td className='c15'>{toFormat(lastRow.col15)}</td>
              <td className='bLeft c16'>{toFormat(lastRow.col16)}</td>
              <td className='c17'>{toFormat(lastRow.col17)}</td>
              <td className='bLeft c18'>{toFormat(lastRow.col18)}</td>
              <td className='c19'>{toFormat(lastRow.col19)}</td>
              <td className='bLeft c20'>{toFormat(lastRow.col20)}</td>
              <td className='c21'>{toFormat(lastRow.col21)}</td>
              <td className='dbLeft c22'>{toFormat(lastRow.col22)}</td>
              <td className='c23'>{toFormat(lastRow.col23)}</td>
              <td className='bLeft c24'>{toFormat(lastRow.col24)}</td>
              <td className='c25'>{toFormat(lastRow.col25)}</td>
              <td className='bLeft c26'>{toFormat(lastRow.col26)}</td>
              <td className='c27'>{toFormat(lastRow.col27)}</td>
              <td className='bLeft c28'>{toFormat(lastRow.col28)}</td>
              <td className='c29'>{toFormat(lastRow.col29)}</td>
              <td className='bLeft c30'>{toFormat(lastRow.col30)}</td>
              <td className='c31'>{toFormat(lastRow.col31)}</td>
              <td className='bRight c32'>{toFormat(lastRow.col32)}</td>
            </tr>
          </tbody>
        </table>
      </div>
      <br></br>
      <br></br>
      <br></br>
      <OverviewPayroll
        wr_id={p.wr_id}
        wr_closing_date={p.wr_closing_date}
        overview={overview}
        adjustedRow={adjustedRow}
      />


    </>
  );
}

export default OverviewTable;