import * as React from 'react';
import { SendQuery2 } from '../Component/ToServer.js';
import Typography from '@mui/material/Typography';
import Grid from '@mui/material/Unstable_Grid2';
import Box from '@mui/material/Box';
import Stack from '@mui/material/Stack';

//MY COMPONENT
import TODCrewAdd from './TODCrewAdd';
import TODTable from './TODTable';
import { sampleCrewList } from '../Context/SampleCrewList.js';

/*
COMPONENT: TourOfDutyPanel
RETURN: a ROW of vessel selected by USER from fetched list (from SQL)

p.vessel:: JSON vessel selected by user
p.wageReport:: JSON wageReport selected by user
p.disabled:: TRUE/FALSE to set if button is disabled
*/

function TourOfDutyPanel(p) {
  const [crewList, setCrewList] = React.useState([{}]);
  //const [TODCrewList, setTODCrewList] = React.useState([]);
  const [updateTrigger, setUpdateTrigger] = React.useState(0);

  const handleUpdate = () => {
    setUpdateTrigger(prev => prev % 2 + 1);
  };

  React.useEffect(() => {
    const fetchData = async () => {
      const result = await SendQuery2({ header: 'CREW_LIST_SIMPLE' }, "ops.php");
      //for OFFLINE use only
      if (result == "Network Error") {
        setCrewList(sampleCrewList);
      } else {
        setCrewList(result.data);
      }
    };
    fetchData();
  }, []);

  /*React.useEffect(() => {
    if (p.wageReport.length !== 0 && p.vessel !== null) {
      const fetchData = async () => {
        const result = await SendQuery2({ header: 'TOD_CREW_LIST' }, "ops.php");
        //for OFFLINE use only
        if (result == "Network Error") {
          setTODCrewList(sampleCrewList);
        } else {
          setTODCrewList(result.data);
        }
      };
      fetchData();
    }
  }, [p.wageReport, p.vessel]);*/

  return (
    <>
      <Box sx={{ m: '10px', display: 'flex' }}>

        <Typography variant='h8'>
          <b>Tour of Duty: {p.vessel?.name}</b>
          <br></br>
          Closing Date: {p.wageReport?.closing_date}
        </Typography>

      </Box>
      <br></br>
      <TODTable
        tod_id={p.vessel?.tod_id}
        updateTrigger={updateTrigger}
        updateTriggerWR={p.wageReport.id}
      />
      <br></br>
      TABLES
      <br></br>
      <TODCrewAdd
        wageReport={p.wageReport}
        tod_id={p.vessel?.tod_id}
        handleUpdate={handleUpdate}
        crewList={crewList}
        disabled={p.disabled}
      />

    </>
  );
}

export default TourOfDutyPanel; 