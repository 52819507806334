import * as React from 'react';

function Dashboard() {
  return (
    <>
      <h1>Welcome to Dashboard!</h1>
    </>
  );

}

export { Dashboard }