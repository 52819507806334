import * as React from 'react';
import './User.css';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableRow from '@mui/material/TableRow';
import AuthContext from "../Context/AuthProvider";


function User() {
  const { userData } = React.useContext(AuthContext);
  const data = Object.entries(userData).map((row) => {
    return (
      <TableRow
        key={userData.id}
        sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
      >
        <TableCell component="th" scope="row" className="udt">
          {row[0]}
        </TableCell>
        <TableCell align="left">{row[1]}</TableCell>
      </TableRow>
    );
  }
  );

  return (
    <>
      <TableContainer>
        <Table sx={{ maxWidth: 650 }} aria-label="simple table">
          <TableBody>
            {data}

          </TableBody>
        </Table>
      </TableContainer>
    </>
  );
}

export { User }

/*
        {
          Object.entries(userData).map((e,v) => {
            return(
              <h4>
                ID: {e[0]} 
                <br></br>
                
              </h4>
            )
          }
          )
        }
        */

/*}
 */