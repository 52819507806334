const sampleCrewTOD2 = [
   {
      "id":25,
      "sign_on":1658592000,
      "sign_off":1664467200,
      "pcb_col":"cat2ka2",
      "name":"NOLDY RONALD LELEH",
      "job_title":"MASTER",
      "ic_passport":"A1275501",
      "nationality":"INDONESIAN",
      "sum_salary":"1750.00",
      "j_salary":[
         {
            "id":25,
            "rate":350,
            "days":5,
            "salary":1750,
            "category":"Daily",
            "start_date":1664121600,
            "end_date":1664467200
         }
      ]
   },
   {
      "id":26,
      "sign_on":0,
      "sign_off":0,
      "pcb_col":"cat2k",
      "name":"ABI ROSANTO SURAHJO",
      "job_title":null,
      "ic_passport":"A9508236",
      "nationality":"INDONESIAN",
      "sum_salary":"10500.00",
      "j_salary":[
         {
            "id":26,
            "rate":350,
            "days":30,
            "salary":10500,
            "category":"Daily",
            "start_date":1664121600,
            "end_date":1666627200
         }
      ]
   },
   {
      "id":27,
      "sign_on":0,
      "sign_off":0,
      "pcb_col":"cat2ka3",
      "name":"AJANG ANYIE WAN",
      "job_title":"MASTER",
      "ic_passport":"671114135753",
      "nationality":"MALAYSIAN",
      "sum_salary":"13050.00",
      "j_salary":[
         {
            "id":27,
            "rate":435,
            "days":30,
            "salary":13050,
            "category":"Daily",
            "start_date":1664121600,
            "end_date":1666627200
         }
      ]
   },
   {
      "id":28,
      "sign_on":0,
      "sign_off":0,
      "pcb_col":"cat2ka1",
      "name":"ARMAN BIN ARIFIN",
      "job_title":"CHIEF OFFICER",
      "ic_passport":"960812127313",
      "nationality":"MALAYSIAN",
      "sum_salary":"6900.00",
      "j_salary":[
         {
            "id":28,
            "rate":230,
            "days":30,
            "salary":6900,
            "category":"Daily",
            "start_date":1664121600,
            "end_date":1666627200
         }
      ]
   },
   {
      "id":29,
      "sign_on":0,
      "sign_off":0,
      "pcb_col":"cat2ka5",
      "name":"AWANGKU ROSLI BIN PG. YUNUS @ AK. YUNOS",
      "job_title":"MASTER",
      "ic_passport":"701125125117",
      "nationality":"MALAYSIAN",
      "sum_salary":"18600.00",
      "j_salary":[
         {
            "id":29,
            "rate":310,
            "days":30,
            "salary":9300,
            "category":"Daily",
            "start_date":1664121600,
            "end_date":1666627200
         },
         {
            "id":48,
            "rate":310,
            "days":30,
            "salary":9300,
            "category":"Daily",
            "start_date":1664121600,
            "end_date":1666627200
         }
      ]
   },
   {
      "id":30,
      "sign_on":0,
      "sign_off":0,
      "pcb_col":"cat2ka3",
      "name":"BUHARI",
      "job_title":"SECOND ENGINEER",
      "ic_passport":"B6547941",
      "nationality":"INDONESIAN",
      "sum_salary":"6600.00",
      "j_salary":[
         {
            "id":30,
            "rate":220,
            "days":30,
            "salary":6600,
            "category":"Daily",
            "start_date":1664121600,
            "end_date":1666627200
         }
      ]
   },
   {
      "id":31,
      "sign_on":0,
      "sign_off":0,
      "pcb_col":"b",
      "name":"GENESIS ANAK SERANG",
      "job_title":"ABLE BODED",
      "ic_passport":"891030135291",
      "nationality":"MALAYSIAN",
      "sum_salary":"1950.00",
      "j_salary":[
         {
            "id":31,
            "rate":65,
            "days":30,
            "salary":1950,
            "category":"Daily",
            "start_date":1664121600,
            "end_date":1666627200
         }
      ]
   },
   {
      "id":32,
      "sign_on":0,
      "sign_off":0,
      "pcb_col":"b",
      "name":"CLARENCE LAING ANAK LIPA",
      "job_title":"THIRD ENGINEER",
      "ic_passport":"971206136133",
      "nationality":"MALAYSIAN",
      "sum_salary":"3450.00",
      "j_salary":[
         {
            "id":32,
            "rate":115,
            "days":30,
            "salary":3450,
            "category":"Daily",
            "start_date":1664121600,
            "end_date":1666627200
         }
      ]
   },
   {
      "id":35,
      "sign_on":0,
      "sign_off":0,
      "pcb_col":"b",
      "name":"DESMOND LIHAN JOK",
      "job_title":"ABLE BODIED",
      "ic_passport":"011107130803",
      "nationality":"MALAYSIAN",
      "sum_salary":"1332.50",
      "j_salary":[
         {
            "id":35,
            "rate":32.5,
            "days":10,
            "salary":325,
            "category":"Quarantine",
            "start_date":1664121600,
            "end_date":1664899200
         },
         {
            "id":45,
            "rate":32.5,
            "days":11,
            "salary":357.5,
            "category":"Standby",
            "start_date":1664985600,
            "end_date":1665849600
         },
         {
            "id":59,
            "rate":65,
            "days":10,
            "salary":650,
            "category":"Daily",
            "start_date":1665849600,
            "end_date":1666627200
         }
      ]
   },
   {
      "id":42,
      "sign_on":0,
      "sign_off":0,
      "pcb_col":"cat3ka2",
      "name":"RAJIS BIN YUSOF",
      "job_title":"MASTER",
      "ic_passport":"830607126703",
      "nationality":"MALAYSIAN",
      "sum_salary":"9300.00",
      "j_salary":[
         {
            "id":42,
            "rate":310,
            "days":30,
            "salary":9300,
            "category":"Daily",
            "start_date":1664121600,
            "end_date":1666627200
         }
      ]
   },
   {
      "id":43,
      "sign_on":0,
      "sign_off":0,
      "pcb_col":"cat2ka3",
      "name":"JOHANES DONBOSCO",
      "job_title":"CHIEF ENGINEER",
      "ic_passport":"C1393123",
      "nationality":"INDONESIAN",
      "sum_salary":"9000.00",
      "j_salary":[
         {
            "id":43,
            "rate":300,
            "days":30,
            "salary":9000,
            "category":"Daily",
            "start_date":1664121600,
            "end_date":1666627200
         }
      ]
   },
   {
      "id":44,
      "sign_on":0,
      "sign_off":0,
      "pcb_col":"cat2ka4",
      "name":"ABDUL HAMIDIN BIN ABDUL HAMID",
      "job_title":"THIRD ENGINEER",
      "ic_passport":"771006125273",
      "nationality":"MALAYSIAN",
      "sum_salary":"3450.00",
      "j_salary":[
         {
            "id":44,
            "rate":115,
            "days":30,
            "salary":3450,
            "category":"Daily",
            "start_date":1664121600,
            "end_date":1666627200
         }
      ]
   },
   {
      "id":46,
      "sign_on":0,
      "sign_off":0,
      "pcb_col":"cat2ka2",
      "name":"JOSEPH ANAK BAJAU",
      "job_title":"ABLE BODIED",
      "ic_passport":"811225135977",
      "nationality":"MALAYSIAN",
      "sum_salary":"900.00",
      "j_salary":[
         {
            "id":46,
            "rate":30,
            "days":30,
            "salary":900,
            "category":"Stand-by",
            "start_date":1664121600,
            "end_date":1666627200
         }
      ]
   },
   {
      "id":47,
      "sign_on":0,
      "sign_off":0,
      "pcb_col":"b",
      "name":"AZMAN BIN ABDUL RAHMAN",
      "job_title":"ABLE BODIED",
      "ic_passport":"950512136665",
      "nationality":"MALAYSIAN",
      "sum_salary":"2100.00",
      "j_salary":[
         {
            "id":47,
            "rate":70,
            "days":30,
            "salary":2100,
            "category":"Daily",
            "start_date":1664121600,
            "end_date":1666627200
         }
      ]
   },
   {
      "id":49,
      "sign_on":0,
      "sign_off":0,
      "pcb_col":"cat2ka5",
      "name":"BARYANTO ABDULAH BAJURI",
      "job_title":"CHIEF ENGINEER",
      "ic_passport":"C6233525",
      "nationality":"INDONESIAN",
      "sum_salary":"4500.00",
      "j_salary":[
         {
            "id":49,
            "rate":150,
            "days":30,
            "salary":4500,
            "category":"Standby",
            "start_date":1664121600,
            "end_date":1666627200
         }
      ]
   },
   {
      "id":50,
      "sign_on":0,
      "sign_off":0,
      "pcb_col":"cat2ka3",
      "name":"FAIRUZ MUNA BIN ZAIDI",
      "job_title":"SECOND ENGINEER",
      "ic_passport":"841231135655",
      "nationality":"MALAYSIAN",
      "sum_salary":"6600.00",
      "j_salary":[
         {
            "id":50,
            "rate":220,
            "days":30,
            "salary":6600,
            "category":"Daily",
            "start_date":1664121600,
            "end_date":1666627200
         }
      ]
   },
   {
      "id":60,
      "sign_on":0,
      "sign_off":0,
      "pcb_col":"cat2k",
      "name":"ZAKERIA BIN BAHTIAR",
      "job_title":"ABLE BODIED",
      "ic_passport":"740303135951",
      "nationality":"MALAYSIAN",
      "sum_salary":"3150.00",
      "j_salary":[
         {
            "id":60,
            "rate":35,
            "days":30,
            "salary":1050,
            "category":"Laid Up",
            "start_date":1664121600,
            "end_date":1666627200
         },
         {
            "id":62,
            "rate":70,
            "days":30,
            "salary":2100,
            "category":"Daily",
            "start_date":1664121600,
            "end_date":1666627200
         }
      ]
   },
   {
      "id":61,
      "sign_on":0,
      "sign_off":0,
      "pcb_col":"cat2ka3",
      "name":"DENNIS ANAK UNGAN",
      "job_title":"ABLE BODIED",
      "ic_passport":"910820136011",
      "nationality":"MALAYSIAN",
      "sum_salary":"2550.00",
      "j_salary":[
         {
            "id":61,
            "rate":85,
            "days":30,
            "salary":2550,
            "category":"Daily",
            "start_date":1664121600,
            "end_date":1666627200
         }
      ]
   },
   {
      "id":63,
      "sign_on":0,
      "sign_off":0,
      "pcb_col":"cat2ka2",
      "name":"NICHLOS NYANGGAI ANAK UMPIE",
      "job_title":"CHIEF OFFICER",
      "ic_passport":"910212136253",
      "nationality":"MALAYSIAN",
      "sum_salary":"6600.00",
      "j_salary":[
         {
            "id":63,
            "rate":220,
            "days":30,
            "salary":6600,
            "category":"Daily",
            "start_date":1664121600,
            "end_date":1666627200
         }
      ]
   }
];

export { sampleCrewTOD2 }