
const sampleAdmin = {
  "id": 1,
  "username": "yongwui",
  "name": "Chew Yong Wui",
  "title": "exco member",
  "edit_date": "2022-10-17 10:33:50",
  "photo": null,
  "department": "EXCO",
  "branch": "Miri",
  "status": null,
  "remark": null,
  "role": "ADMIN",
};
//const sampleVesselList = [{}];

const sampleVesselList = [
  {
    id: 1,
    name: 'Tegas Maju',
    type: 'Fast Crew Boat',
    imo: 12345678,
    official_no: '334433',
    year_registered: 2014,
    photo: '/vessel/tmj.jpg',
    "edit_date": "2022-10-17 10:33:50",
    user_id: 100,
    'status': 'active',
    short_name: 'TMJ',
  },
  {
    id: 2,
    name: 'Tegas Mulia',
    type: 'Fast Crew Boat',
    imo: 12348888,
    official_no: '338888',
    year_registered: 2021,
    photo: '/vessel/tml.jpg',
    "edit_date": "2022-10-17 10:33:50",
    user_id: 100,
    'status': 'active',
    short_name: 'TML',
  },
  {
    id: 3,
    name: 'Ocean Pride',
    type: 'Fast Crew Boat',
    imo: 12342222,
    official_no: '332222',
    year_registered: 2013,
    photo: '/vessel/op.jpg',
    "edit_date": "2022-10-17 10:33:50",
    user_id: 100,
    'status': 'active',
    short_name: 'OCP',
  },
  {
    id: 4,
    name: 'Ocean Ranger',
    type: 'Fast Crew Boat',
    imo: 4444,
    official_no: '22222',
    year_registered: 2013,
    photo: '/vessel/ocr.jpg',
    "edit_date": "2022-10-17 10:33:50",
    user_id: 100,
    'status': 'active',
    short_name: 'OCR',
  }
]

export { sampleAdmin, sampleVesselList }


