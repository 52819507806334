import * as React from 'react';
import AuthContext from "../Context/AuthProvider";
import Stack from '@mui/material/Stack';
import { SendQuery2 } from '../Component/ToServer.js';
import { sampleWageReportList } from '../Context/SampleWage.js';

import { sampleVesselList } from '../Context/SampleData.js';
import TourofDutyPanel from "./TourOfDutyPanel.js";
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import TextField from '@mui/material/TextField';
import Typography from '@mui/material/Typography';

//MYCOMPONENTS
import DropMenuWageReport from '../Component/DropMenuWageReport.js';
import DropMenuVesselTOD from '../Component/DropMenuVesselTOD.js';
import TourOfDutyPanel from './TourOfDutyPanel';
import TODVesselAdd from './TODVesselAdd';


function TourOfDuty() {
  const { userData } = React.useContext(AuthContext);
  const [wageReport, setWageReport] = React.useState([]);
  const [vessel, setVessel] = React.useState(null);
  const [vslDisabled, setVslDisabled] = React.useState(true);
  const [crewDisabled, setCrewDisabled] = React.useState(true);
  const [updateTrigger, setUpdateTrigger] = React.useState(0);
  const handleUpdate = () => {
    setUpdateTrigger(prev => prev % 2 + 1);
  };
  //NEED TO DISABLE setCrewDisable WHEN VESSEL IS NULL
  React.useEffect(() => {
    if (wageReport.length !== 0) {
      setVslDisabled(false);
      setVessel(null);
      setCrewDisabled(true);
    }
  }, [wageReport]);

  React.useEffect(() => {
    if (vessel !== null) {
      setCrewDisabled(false);
    }
  }, [vessel]);

  return (
    <>
      <Typography variant='h4' align='Left' sx={{ paddingBottom: '0.2cm', paddingLeft: '0.2cm' }}>
        Tour of Duty
      </Typography>

      <Box sx={{ width: '320px', float: 'left' }}>
        <Stack spacing={3}>
          <DropMenuWageReport setWageReport={setWageReport} />
          <div>
            <DropMenuVesselTOD 
            setVessel={setVessel} 
            updateTrigger={updateTrigger} 
            wage_report_id={wageReport.id}
            />
            
            <TODVesselAdd disabled={vslDisabled} wage_report_id={wageReport?.id} handleUpdate={handleUpdate} />

          </div>
        </Stack>
      </Box>

      <Box sx={{ width: '950px', float: 'left' }}>
        <TourOfDutyPanel vessel={vessel} wageReport={wageReport} disabled={crewDisabled} />
      </Box>

    </>
  );

}

export { TourOfDuty }