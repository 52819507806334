import React from "react";
import { AuthProvider } from './Context/AuthProvider.js';
import { BrowserRouter, Routes, Route, Navigate, Outlet, useLocation, Link } from "react-router-dom";
//import './App.css';

import Button from '@mui/material/Button';
import ResponsiveDrawer from "./Navigation/Drawer.js";

import '@fontsource/roboto/300.css';
import '@fontsource/roboto/400.css';
import '@fontsource/roboto/500.css';
import '@fontsource/roboto/700.css';

function App() {
  return (

    <React.StrictMode>
      <BrowserRouter>
        <AuthProvider>
          <Routes>
            <Route path="/*" element={<ResponsiveDrawer />} />
          </Routes>
        </AuthProvider>
      </BrowserRouter>
    </React.StrictMode>

  );
}

export default App;
