import * as React from 'react';
import { SendQuery2 } from './ToServer.js';
import { sampleWageReportList } from '../Context/SampleWage.js';
//DROPDOWN MENU
import MenuItem from '@mui/material/MenuItem';
import Select from '@mui/material/Select';
import FormControl from '@mui/material/FormControl';
import InputLabel from '@mui/material/InputLabel';

/*
COMPONENT: DropMenueWageReport
RETURN: a ROW of WageReport selected by USER from fetched list (from SQL)

p.updateTrigger:: updateTrigger for the WageReportList (refetch from SQL)
p.setWageReport:: sets ROW of wageReport the user selected from fetched from SQL (RETURN)
*/

function DropMenuWageReport(p) {
  const data = { header: 'WAGE_REPORT_LIST' };
  const [wageReportList, setWageReportList] = React.useState([{}]);
  /*const menuList = Object.entries(wageReportList).map((row) => {
    return (
      <MenuItem value={row[0]}>
        {row[1].name}
      </MenuItem>
    )
  });*/

  const menuList = wageReportList.map((row, index) => {
    return (
      <MenuItem value={index}>
        {index + 1} | {row['closing_date']}
      </MenuItem>
    );
  });

  const handleSelect = (e) => {
    p.setWageReport(wageReportList[e.target.value]);
  }

  React.useEffect(() => {
    const fetchData = async () => {
      const result = await SendQuery2(data, "ops.php");

      //for OFFLINE use only
      if (result == "Network Error") {
        setWageReportList(sampleWageReportList);
      } else {
        setWageReportList(result.data);
      }

    };
    fetchData();
  }, [p.updateTrigger]);
  return (
    <FormControl variant="standard" sx={{ m: 1, minWidth: 120, float: 'left' }}>
      <InputLabel>Wage Report</InputLabel>
      <Select
        labelId="demo-simple-select-standard-label"
        id="demo-simple-select-standard"
        onChange={handleSelect}
      >
        {menuList}

      </Select>
    </FormControl>
  );
}

export default DropMenuWageReport;