import * as React from 'react';
import { Routes, Route } from "react-router-dom";
import RequireAuth from './RequireAuth';


import { Unauthorized } from './Unauthorized';
import { Dashboard } from '../Dashboard/Dashboard.js';
import { User } from '../User/User.js';
import { Layout } from './Layout.js';
import { Login } from '../Login/Login.js';

import RequireWageAuth from './RequireWageAuth';
import { TourOfDuty } from '../Calculator/TourOfDuty.js';
import { WageOverview } from '../Calculator/WageOverview.js';
import WageReport from '../Calculator/WageReport.js';

import RequireFleetAuth from './RequireFleetAuth';
import AddVessel from '../Fleet/AddVessel.js';
import ListVessel from '../Fleet/ListVessel.js';

function MainRoutes() {
  return (
    <Routes>
      <Route path="/" element={<Layout />}>
        {/* Public Routes */}
        <Route path="login" element={<Login />} />
        <Route path="unauthorized" element={<Unauthorized />} />
        <Route path="dashboard" element={<Dashboard />} />
        <Route path="user" element={<User />} />
        {/* Private Routes */}
        <Route element={<RequireWageAuth />}>
          <Route path="wageoverview" element={<WageOverview />} />
          <Route path="tourofduty" element={<TourOfDuty />} />
          <Route path="wagereport" element={<WageReport />} />

        </Route>
        <Route element={<RequireFleetAuth />}>
          <Route path="addvessel" element={<AddVessel />} />
          <Route path="listvessel" element={<ListVessel />} />
        </Route>
      </Route>
    </Routes>
  );

}

export default MainRoutes;