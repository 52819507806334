const sampleOverviewMT = [];

const sampleOverview = [
   {
      "name":"DENNIS NGU",
      "nationality":"MY",
      "ic_passport":"900411137495",
      "wife_status":"WNW KA2",
      "child_status":"KA2",
      "vessel":[
         {
            "id":124,
            "short_name":"TMJ",
            "category":"Daily",
            "start_date":1664121600,
            "end_date":1664553600,
            "days":6,
            "rate":70,
            "ratio":0.285714,
            "salary":420,
            "total_salary":1470,
            "total_pcb":0,
            "employee_epf":163,
            "employee_socso":7.25,
            "employee_eis":2.9,
            "employer_epf":193,
            "employer_socso":25.35,
            "employer_eis":2.9
         },
         {
            "id":125,
            "short_name":"TMJ",
            "category":"Daily",
            "start_date":1665417600,
            "end_date":1666627200,
            "days":15,
            "rate":70,
            "ratio":0.714286,
            "salary":1050,
            "total_salary":1470,
            "total_pcb":0,
            "employee_epf":163,
            "employee_socso":7.25,
            "employee_eis":2.9,
            "employer_epf":193,
            "employer_socso":25.35,
            "employer_eis":2.9
         }
      ]
   },
   {
      "name":"ABDUL HAFIZ BIN ABDUL HAMID",
      "nationality":"MY",
      "ic_passport":"901224126373",
      "wife_status":"WNW KA3",
      "child_status":"KA3",
      "vessel":[
         {
            "id":126,
            "short_name":"TMJ",
            "category":"234f",
            "start_date":1664553600,
            "end_date":1667145600,
            "days":31,
            "rate":11,
            "ratio":1,
            "salary":341,
            "total_salary":341,
            "total_pcb":0,
            "employee_epf":40,
            "employee_socso":1.75,
            "employee_eis":0.7,
            "employer_epf":47,
            "employer_socso":6.15,
            "employer_eis":0.7
         }
      ]
   },
   {
      "name":"ABDUL HAMIDIN BIN ABDUL HAMID",
      "nationality":"MY",
      "ic_passport":"771006125273",
      "wife_status":"WNW KA4",
      "child_status":"KA4",
      "vessel":[
         {
            "id":115,
            "short_name":"OCR",
            "category":"Daily",
            "start_date":1664553600,
            "end_date":1667145600,
            "days":31,
            "rate":115,
            "ratio":0.320305,
            "salary":3565,
            "total_salary":11130,
            "total_pcb":1159.55,
            "employee_epf":1232,
            "employee_socso":24.75,
            "employee_eis":9.9,
            "employer_epf":1344,
            "employer_socso":86.65,
            "employer_eis":9.9
         },
         {
            "id":118,
            "short_name":"OCR",
            "category":"Daily",
            "start_date":1664553600,
            "end_date":1667145600,
            "days":31,
            "rate":115,
            "ratio":0.320305,
            "salary":3565,
            "total_salary":11130,
            "total_pcb":1159.55,
            "employee_epf":1232,
            "employee_socso":24.75,
            "employee_eis":9.9,
            "employer_epf":1344,
            "employer_socso":86.65,
            "employer_eis":9.9
         },
         {
            "id":128,
            "short_name":"TMJ",
            "category":"allowance",
            "start_date":1664553600,
            "end_date":1667145600,
            "days":31,
            "rate":50,
            "ratio":0.139263,
            "salary":1550,
            "total_salary":11130,
            "total_pcb":1159.55,
            "employee_epf":1232,
            "employee_socso":24.75,
            "employee_eis":9.9,
            "employer_epf":1344,
            "employer_socso":86.65,
            "employer_eis":9.9
         },
         {
            "id":131,
            "short_name":"TMJ",
            "category":"qwertt",
            "start_date":1664553600,
            "end_date":1667145600,
            "days":31,
            "rate":50,
            "ratio":0.139263,
            "salary":1550,
            "total_salary":11130,
            "total_pcb":1159.55,
            "employee_epf":1232,
            "employee_socso":24.75,
            "employee_eis":9.9,
            "employer_epf":1344,
            "employer_socso":86.65,
            "employer_eis":9.9
         },
         {
            "id":135,
            "short_name":"TMJ",
            "category":"ty",
            "start_date":1664640000,
            "end_date":1667145600,
            "days":30,
            "rate":30,
            "ratio":0.0808625,
            "salary":900,
            "total_salary":11130,
            "total_pcb":1159.55,
            "employee_epf":1232,
            "employee_socso":24.75,
            "employee_eis":9.9,
            "employer_epf":1344,
            "employer_socso":86.65,
            "employer_eis":9.9
         }
      ]
   },
   {
      "name":"EGY RUNGA",
      "nationality":"ID",
      "ic_passport":"X412772",
      "wife_status":"WNW KA3",
      "child_status":"KA3",
      "vessel":[
         {
            "id":74,
            "short_name":"OCR",
            "category":"Daily",
            "start_date":1664553600,
            "end_date":1667145600,
            "days":31,
            "rate":310,
            "ratio":0.775,
            "salary":9610,
            "total_salary":12400,
            "total_pcb":1504.35,
            "employee_epf":0,
            "employee_socso":0,
            "employee_eis":0,
            "employer_epf":0,
            "employer_socso":61.9,
            "employer_eis":0
         },
         {
            "id":89,
            "short_name":"OCR",
            "category":"Allowance",
            "start_date":1664553600,
            "end_date":1667145600,
            "days":31,
            "rate":10,
            "ratio":0.025,
            "salary":310,
            "total_salary":12400,
            "total_pcb":1504.35,
            "employee_epf":0,
            "employee_socso":0,
            "employee_eis":0,
            "employer_epf":0,
            "employer_socso":61.9,
            "employer_eis":0
         },
         {
            "id":90,
            "short_name":"OCR",
            "category":"Daily",
            "start_date":1667059200,
            "end_date":1667145600,
            "days":2,
            "rate":310,
            "ratio":0.05,
            "salary":620,
            "total_salary":12400,
            "total_pcb":1504.35,
            "employee_epf":0,
            "employee_socso":0,
            "employee_eis":0,
            "employer_epf":0,
            "employer_socso":61.9,
            "employer_eis":0
         },
         {
            "id":91,
            "short_name":"OCR",
            "category":"Daily",
            "start_date":1667145600,
            "end_date":1667145600,
            "days":1,
            "rate":310,
            "ratio":0.025,
            "salary":310,
            "total_salary":12400,
            "total_pcb":1504.35,
            "employee_epf":0,
            "employee_socso":0,
            "employee_eis":0,
            "employer_epf":0,
            "employer_socso":61.9,
            "employer_eis":0
         },
         {
            "id":136,
            "short_name":"TMJ",
            "category":"sss",
            "start_date":1664553600,
            "end_date":1667145600,
            "days":31,
            "rate":50,
            "ratio":0.125,
            "salary":1550,
            "total_salary":12400,
            "total_pcb":1504.35,
            "employee_epf":0,
            "employee_socso":0,
            "employee_eis":0,
            "employer_epf":0,
            "employer_socso":61.9,
            "employer_eis":0
         }
      ]
   },
   {
      "name":"JOHANES DONBOSCO",
      "nationality":"ID",
      "ic_passport":"C1393123",
      "wife_status":"WNW KA3",
      "child_status":"KA3",
      "vessel":[
         {
            "id":51,
            "short_name":"OCR",
            "category":"twenty",
            "start_date":1664553600,
            "end_date":1667145600,
            "days":31,
            "rate":20,
            "ratio":0.104818,
            "salary":620,
            "total_salary":5915,
            "total_pcb":173.1,
            "employee_epf":0,
            "employee_socso":0,
            "employee_eis":0,
            "employer_epf":0,
            "employer_socso":61.9,
            "employer_eis":0
         },
         {
            "id":102,
            "short_name":"OCR",
            "category":"test4",
            "start_date":1664553600,
            "end_date":1667145600,
            "days":31,
            "rate":45,
            "ratio":0.235841,
            "salary":1395,
            "total_salary":5915,
            "total_pcb":173.1,
            "employee_epf":0,
            "employee_socso":0,
            "employee_eis":0,
            "employer_epf":0,
            "employer_socso":61.9,
            "employer_eis":0
         },
         {
            "id":113,
            "short_name":"OCR",
            "category":"Daily",
            "start_date":1664553600,
            "end_date":1664726400,
            "days":3,
            "rate":300,
            "ratio":0.152156,
            "salary":900,
            "total_salary":5915,
            "total_pcb":173.1,
            "employee_epf":0,
            "employee_socso":0,
            "employee_eis":0,
            "employer_epf":0,
            "employer_socso":61.9,
            "employer_eis":0
         },
         {
            "id":114,
            "short_name":"OCR",
            "category":"Daily",
            "start_date":1664553600,
            "end_date":1665331200,
            "days":10,
            "rate":300,
            "ratio":0.507185,
            "salary":3000,
            "total_salary":5915,
            "total_pcb":173.1,
            "employee_epf":0,
            "employee_socso":0,
            "employee_eis":0,
            "employer_epf":0,
            "employer_socso":61.9,
            "employer_eis":0
         }
      ]
   },
   {
      "name":"ZAKERIA BIN BAHTIAR",
      "nationality":"MY",
      "ic_passport":"740303135951",
      "wife_status":"WNW K",
      "child_status":"K",
      "vessel":[
         {
            "id":81,
            "short_name":"OCR",
            "category":"Daily",
            "start_date":1664553600,
            "end_date":1667145600,
            "days":31,
            "rate":70,
            "ratio":0.482759,
            "salary":2170,
            "total_salary":4495,
            "total_pcb":89.6,
            "employee_epf":495,
            "employee_socso":22.25,
            "employee_eis":8.9,
            "employer_epf":585,
            "employer_socso":77.85,
            "employer_eis":8.9
         },
         {
            "id":82,
            "short_name":"OCR",
            "category":"laid up",
            "start_date":1664553600,
            "end_date":1667145600,
            "days":31,
            "rate":50,
            "ratio":0.344828,
            "salary":1550,
            "total_salary":4495,
            "total_pcb":89.6,
            "employee_epf":495,
            "employee_socso":22.25,
            "employee_eis":8.9,
            "employer_epf":585,
            "employer_socso":77.85,
            "employer_eis":8.9
         },
         {
            "id":116,
            "short_name":"OCR",
            "category":"two5",
            "start_date":1664553600,
            "end_date":1667145600,
            "days":31,
            "rate":25,
            "ratio":0.172414,
            "salary":775,
            "total_salary":4495,
            "total_pcb":89.6,
            "employee_epf":495,
            "employee_socso":22.25,
            "employee_eis":8.9,
            "employer_epf":585,
            "employer_socso":77.85,
            "employer_eis":8.9
         }
      ]
   },
   {
      "name":"AMIRUDIN BIN TALA",
      "nationality":"MY",
      "ic_passport":"620916125151",
      "wife_status":"WNW KA4",
      "child_status":"KA4",
      "vessel":[
         {
            "id":117,
            "short_name":"OCR",
            "category":"test",
            "start_date":1664553600,
            "end_date":1667145600,
            "days":31,
            "rate":150,
            "ratio":0.584906,
            "salary":4650,
            "total_salary":7950,
            "total_pcb":460.35,
            "employee_epf":0,
            "employee_socso":0,
            "employee_eis":0,
            "employer_epf":320,
            "employer_socso":61.9,
            "employer_eis":0
         },
         {
            "id":119,
            "short_name":"OCR",
            "category":"Daily",
            "start_date":1664553600,
            "end_date":1665763200,
            "days":15,
            "rate":220,
            "ratio":0.415094,
            "salary":3300,
            "total_salary":7950,
            "total_pcb":460.35,
            "employee_epf":0,
            "employee_socso":0,
            "employee_eis":0,
            "employer_epf":320,
            "employer_socso":61.9,
            "employer_eis":0
         }
      ]
   },
   {
      "name":"YUSRI ABADI",
      "nationality":"ID",
      "ic_passport":"C0804654",
      "wife_status":"WNW KA4",
      "child_status":"KA4",
      "vessel":[
         {
            "id":120,
            "short_name":"OCR",
            "category":"Daily",
            "start_date":1665417600,
            "end_date":1666627200,
            "days":15,
            "rate":310,
            "ratio":0.833333,
            "salary":4650,
            "total_salary":5580,
            "total_pcb":123.05,
            "employee_epf":0,
            "employee_socso":0,
            "employee_eis":0,
            "employer_epf":0,
            "employer_socso":61.9,
            "employer_eis":0
         },
         {
            "id":121,
            "short_name":"OCR",
            "category":"Daily",
            "start_date":1664121600,
            "end_date":1664294400,
            "days":3,
            "rate":310,
            "ratio":0.166667,
            "salary":930,
            "total_salary":5580,
            "total_pcb":123.05,
            "employee_epf":0,
            "employee_socso":0,
            "employee_eis":0,
            "employer_epf":0,
            "employer_socso":61.9,
            "employer_eis":0
         }
      ]
   }
];

export { sampleOverview, sampleOverviewMT }