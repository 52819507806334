import * as React from 'react';
import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';

//DISPLAY TABLE
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableRow from '@mui/material/TableRow';

//MY COMPONENTS
import DelVessel from './DelVessel.js';
import DropMenuVessel from '../Component/DropMenuVessel.js';

function ListVessel() {
  const [vessel, setVessel] = React.useState({});
  const [showDel, setShowDel] = React.useState(false);
  const [updateList, setUpdateList] = React.useState(0);

  const displayData = Object.entries(vessel).map((row) => {
    return (
      <TableRow sx={{ '&:last-child td, &:last-child th': { border: 0 } }}>
        <TableCell component="th" scope="row" className="udt" sx={{ width: '4cm' }}>
          {row[0]}
        </TableCell>
        <TableCell align="left">{row[1]}</TableCell>
      </TableRow>
    );
  });

  return (
    <>
      <Typography variant='h4' align='Left' sx={{ paddingBottom: '1cm', paddingLeft: '0.2cm' }}>
        List of Vessels!!
      </Typography>
      <DropMenuVessel updateTrigger={updateList} setVessel={setVessel} setShowDel={setShowDel}/>
      <TableContainer>
        <Table sx={{ maxWidth: 450 }} aria-label="simple table">
          <TableBody>
            {displayData}
          </TableBody>
        </Table>
      </TableContainer>
      <Box width='450px' sx={{ pt: '15px' }}>
        {//shows the below COMPONENT only if vessel is SELECTED
          showDel
            ? <DelVessel vid={vessel.id} vName={vessel.name} setUpdateList={setUpdateList} />
            : <p>{showDel}</p>
        }
      </Box>
    </>
  );
}
export default ListVessel;