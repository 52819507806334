import * as React from 'react';
import { Typography } from '@mui/material';

//TOD_GREAT_STATUTORY_UPDATE

//MY COMPONENT
import DropMenuWageReport from '../Component/DropMenuWageReport';
import OverviewTable from './OverviewTable';

function WageOverview() {
  const [wr, setWR] = React.useState([]);


  return (
    <>
      <Typography variant='h4'>Wage Overview</Typography>
      <DropMenuWageReport
        setWageReport={setWR}
      />
      <br></br>
      <br></br>
      <br></br>
      {//JSON.stringify(wr)
      }
      
      <OverviewTable wr_id={wr.id} wr_closing_date={wr.closing_date} />
    </>
  );

}
export { WageOverview }