import * as React from 'react';
import AuthContext from "../Context/AuthProvider";
import { SendQuery2 } from '../Component/ToServer.js';
import { sampleWageReportList } from '../Context/SampleWage.js';
import Typography from '@mui/material/Typography';

import Box from '@mui/material/Box';

import AddWageReport from './AddWageReport.js';
import DelWageReport from './DelWageReport.js';
import ApproveWageReport from './ApproveWageReport.js';
import VerifyWageReport from './VerifyWageReport.js';

//DISPLAY TABLE
import Table from '@mui/material/Table';
import TableHead from '@mui/material/TableHead';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableRow from '@mui/material/TableRow';

function WageReport() {
  const { userData } = React.useContext(AuthContext);
  const [updateList, setUpdateList] = React.useState(1);
  const [wageReport, setWageReport] = React.useState([{}]);
  //const [data, setData] = React.useState({ header: "WAGE_REPORT_LIST" });
  const [verifyDisable, setVerifyDisable] = React.useState(true);
  const [approveDisable, setApproveDisable] = React.useState(true);
  //in SQL:: FROM_UNIXTIME(1668241914) before inserting into table
  //suggestions:: SELECT DATE_FORMAT(start_date, '%D %M %Y') FROM `wage_report` WHERE 1;

  const displayData = wageReport.map((row, i) => {
    return (
      <TableRow key={i} sx={{ '&:last-child td, &:last-child th': { border: 0 } }}>
        <TableCell align="right">{row['id']} </TableCell>
        <TableCell align="left">{row["opening_date"]} </TableCell>
        <TableCell align="left">{row["closing_date"]}</TableCell>
        <TableCell align="center">{row["prepared_by"]}<br /> {row["prepared_date"]}</TableCell>
        <TableCell align="center">
          {
            row["verified_by"] == null
              ? <VerifyWageReport setUpdateToken={setUpdateList} row={row} disabled={verifyDisable} />
              : <>{row["verified_by"]}<br />{row["verified_date"]}</>
          }
        </TableCell>
        <TableCell align="center">
          {
            row["approved_by"] == null
              ? <ApproveWageReport setUpdateToken={setUpdateList} row={row} disabled={approveDisable} />
              : <>{row["approved_by"]}<br />{row["approved_date"]}</>
          }
        </TableCell>

      </TableRow>
    );
  }
  );

  React.useEffect(() => {
    
    switch (userData.role) {
      case 'ADMIN':
      case 'EXCO':
        setApproveDisable(false);
        setVerifyDisable(false);
        break;
      case 'CREW MGR':
      case 'ACCOUNTS':
        setApproveDisable(true);
        setVerifyDisable(false);
        break;
      default:
        setApproveDisable(true);
        setVerifyDisable(true);
        break;
    }


    const fetchData = async () => {
      const result = await SendQuery2({ header: "WAGE_REPORT_LIST" }, "ops.php");
      //setFleet(result.data);

      //for OFFLINE use only
      if (result === "Network Error") {
        setWageReport(sampleWageReportList);
      } else {
        setWageReport(result.data);
      }

    };
    fetchData();
  }, [updateList]);

  return (
    <>
      <Typography variant='h4' align='Left' sx={{ paddingBottom: '1cm', paddingLeft: '0.2cm' }}>
        Monthly Wage Reports
      </Typography>
      <TableContainer>
        <Table sx={{ maxWidth: 800 }} size="small" aria-label="simple table">
          <TableHead>
            <TableRow>
              <TableCell align="right">ID.</TableCell>
              <TableCell >Opening Date</TableCell>
              <TableCell >Closing Date</TableCell>
              <TableCell align="center">Prepared By</TableCell>
              <TableCell align="center">Verified By</TableCell>
              <TableCell align="center">Approved By</TableCell>
            </TableRow>
          </TableHead>

          <TableBody>
            {displayData
            }
          </TableBody>
        </Table>
      </TableContainer>
      <Box sx={{ p: '20px' }}>
        <Box sx={{ maxWidth: '350px', float: 'left' }}>
          <AddWageReport setUpdateToken={setUpdateList} />
        </Box>
        <Box sx={{ maxWidth: '350px', float: 'left', ml: '2cm' }}>
          <DelWageReport setUpdateToken={setUpdateList} wageReportList={wageReport} />
        </Box>
      </Box>
    </>
  );

}
export default WageReport;